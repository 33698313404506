.header-text-1 {
    font-size: 1.1rem !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

.header-text-2 {
    font-size: 1.2rem !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

.header-text-3 {
    font-size: 1.3rem !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

.header-text-4 {
    font-size: 1.4rem !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

.header-text-5 {
    font-size: 1.5rem !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

.text-viq-primary {
    color: #187EB1;
}

.hr-css {
    color: #2fabe1;
    /* background-color: #2fabe1; */
}

.hr-dashed {
    border-top: 4px dashed #dadada;
}

.vl {
    border-left: 4px dashed #dadada;
    height: 85%;
    position: relative;
  }

.card-wizard[data-color=viq] .moving-tab {
    background-color: #2fabe1 !important;
}

.img-icon {
    width: 24px;
    height: 24px;
}

.text-support {
    font-size: 14px !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    line-height: 1.2rem !important;
}

.white-text {
    color: white !important;
}

.text-form-note-l {
    font-size: 14px !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    line-height: 1.2rem !important;
    font-weight: 500 !important;
    color: black !important;
}
.text-form-note-r {
    font-size: 14px !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    line-height: 1.2rem !important;
    font-weight: 400 !important;
    color: blue !important;
}

.sidebar .logo-img img {
    top: 16px !important;
    left: 11px;
    position: absolute;
}

.sidebar .logo {
    height: 75px;
    background-color: #2fabe1;
}

.card {
    /* margin-top: 0px !important; */
    margin-bottom: 10px !important;
}

.main-content {
    padding-bottom: 0px !important;
}

.show-logo {
    display: block;
}

.hide-logo {
    display: none !important;
}

.dropdown-left-manual {
    right: 0;
    left: auto;
    padding-left: 1px;
    padding-right: 1px;
}


.btn-viq-primary {
    border: #187EB1;
    background: #187EB1;
    text-transform: capitalize !important;
    font-size: 1rem;
    color: white !important;
}

.btn-viq-secondary {
    border: #212121;
    background: #5b5b5b;
    text-transform: capitalize !important;
    font-size: 1rem;
    color: white;
}

.btn-viq-primary-outline {
    border-color: #187EB1 !important;
    color: #187EB1 !important;
    text-transform: capitalize !important;
}

.btn-viq:hover,
.btn-viq:focus,
.btn-viq:active {
    background-color: #2CABE2 !important;
}

.btn-viq.active {
    background-color: #2CABE2 !important;
}

.navbar {
    /* background-color: #2CABE2!important; */
    /* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(199,234,249,1) 56%, rgba(199,234,249,1) 75%, rgba(10,162,227,1) 100%); */
    /* background-image: linear-gradient(to left, #2CABE2, #fff); */
    background-color: transparent !important;
    color: #187EB1;
    /* background-color: #2CABE2!important; */
    /* box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12), 0 0px 0px -5px rgba(0, 0, 0, 0.15); */
    box-shadow: none;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    /* height: calc(100vh - 59px); */
    width: 260px;
    background: #fff;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.56), 0 1px 2px 0px rgba(0, 0, 0, 0.12), 0 1px 1px -5px rgba(0, 0, 0, 0.2);
}

.pwd-validation {
    margin-top: 60px;
}

.password-text {
    color: #757575;
    line-height: 1em;
}

.password-text-underline {
    text-decoration: line-through !important;
    color: black;
}

.card .card-footer {
    height: 45px !important;
    margin: 0 15px 0px 15px !important;
}

.card {
    margin-bottom: 0px !important;
}

.card-tab {
    background-color: transparent !important;
    box-shadow: none !important;
}

.copy-rights {
    text-align: center;
    color: black;
    font-size: 13px;
}

.product-license-discription {
    color: black;
    padding-top: 25px;
    font-size: 14px;
    padding-left: 5%;
    padding-right: 5%;
}

/* height: calc(100vh - 690px) !important; */
.ngx-file-drop__drop-zone {
    height: 44vh !important;
    margin-bottom: 5px !important;
    width: 100% !important;
    border: 4px dashed #dadada !important; 
    border-radius: 15px !important;
}

/* height: calc(100vh - 690px) !important; */
.ngx-file-drop__content {
    height: 30px !important;
    width: 100% !important;
    display: block !important;
    margin-top: 65px !important;
    color: black !important;
}
@media (min-width: 576px) {
    .col-sm-6 {
        max-width: 100%;
    }
}

.mat-dialog-container {
    padding: 0 !important;
    margin-right: 10px !important;
    margin-top: 5px !important;
}

.mat-dialog-content {
    margin: 0 !important;
    overflow: auto !important;
}

.mat-dialog-title {
    margin-top: 10px !important;
    margin-right: 20px !important;
    margin-bottom: 5px !important;
    margin-left: 20px !important;
    font-size: 1.1rem !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

.card-wizard .mat-form-field {
    width: 100% !important;
}

.card-wizard {
    box-shadow: none;
    border-color: #b1e2f9;
}

.card-wizard .moving-tab {
    font-size: 18px;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    text-transform: none;
    color: #fff;
    font-weight: 500;
}

.nav-pills .nav-item .nav-link {
    text-transform: none;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 15px;
    font-weight: 500;
}

.navbar-text {
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 1.2rem;
    font-weight: 500 !important;
    color: rgb(43, 43, 43) !important;
}

.profile-css {
    text-align: right !important;
    /* width: 300px !important; */
}

.navbar-text-profile {
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 1.3rem;
    font-weight: 700 !important;
    color: #187EB1 !important;
}

.navbar-text-role {
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 0.8rem;
    font-weight: 200 !important;
    color: black !important;
    text-align: left;
}

.material-icons-outlined-upload {
    font-size: 85px !important;
    line-height: 1 !important;
    color: #2fabe1;
    text-align: center;
}

.account-icon {
    font-size: 48px !important;
    line-height: 1 !important;
    color: #2fabe1;
    text-align: center;
    margin-right: 10px !important;
}

.upload-icon-dragzone {
    text-align: center !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 1.1rem !important;
    color: black;
}

.drag-down-button {
    border: none;
    background-color: white;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 1.3rem !important;
    color: #2fabe1;
    text-align: center;
} 

.valid-feedback.feedback-icon,
.invalid-feedback.feedback-icon {
    position: absolute;
    width: auto;
    bottom: 10px;
    right: 10px;
    margin-top: 0;
}

.file-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mat-chip {
    width: 100% !important;
    height: auto !important; 
    line-height: 1.1em !important; 
    text-align: left !important;
}

.mat-text-span {
    width: 95% !important;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mat-icon-text {
    font-size: 18px !important;
}

.mat-icon-conf {
    font-weight: 300 !important;
}

.mat-icon-div {
    position: absolute;
    right: 12px;
    opacity: 1 !important;
    color: #929292 !important;
    margin-top: 5px;
}

.main-panel {
    background-color: #f5fafc !important;
}

.main-panel .main-content {
    margin-top: 20px;
    padding: 30px 8px;
    min-height: calc(100vh - 59px);
    margin-bottom: 40px;
    background-color: #f5fafc !important;
}

.is-admin {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.panelButtonclass {
    box-sizing: content-box;
    -ms-touch-action: none;
    font-family: Verdana, Arial, sans-serif;
    font-style: normal;
    font-size: 13px;
    border-color: none !important;
    background: none !important;
    border: none !important;
    background-color: none;
}

.panelButtonclass:hover {
    /* background-color: #2CABE2; */
    border: none;
}

.mt-70 {
    margin-top: 70px;
}

.primary-tooltip {
    background-color: lightgray !important;
    color: black !important;
    font-style: bold !important;
    font-size: small !important;
}

.no-hover {
    cursor: default !important;
}

.user-listicon {
    width: 23px !important;
    cursor: pointer !important;
}

.jobSearchButton {
    margin-top: -2px !important;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 5px;
}

.jobSearchByDatesButton {
    margin-top: -2px !important;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 5px;
}

/* @media (min-width: 1674px) { */
@media (min-width: 1200px) {
        .single-line {
            display: inline !important;
        }

        .double-line {
            display: none !important;
        }

        .jobSearchButton {
            margin-top: -42px !important;
        }

        .banner-close-button {
            margin-top: 6%;
            margin-right: 18%;
        }
    }
    @media (min-width: 1674px) {
        .banner-close-button {
            margin-top: 4%;
            margin-right: 26%;
        }
    }

    @media (max-width: 1200px) {

        .jobSearchButton {
            margin-top: -2px !important;
        }

        .double-line {
        display: inline !important;
    }

    .single-line {
        display: none !important;
    }
}

.main-panel .main-content {
    margin-bottom: 0px !important;
}

/* .card .card-body {
    padding: 0 0 0 0 !important;
  } */
/* .card-icon{
      margin-bottom: 5px !important;
  }
   */
/* .cdk-overlay-pane {
    width: 45% !important;
} */

/* .perfect-scrollbar-on{ 
    overflow: hidden !important;   
   } */
/* .perfect-scrollbar-off{
    overflow: hidden !important;   
   } */
.footer {
    position: fixed !important;
    color: black;
}

@media (min-width: 800px) {
    .companylogoimage {
        width: 237px;
    }
}

@media (max-width: 799px) {
    .companylogoimage {
        width: 180px;
    }
}

@media (min-width: 1200px) {
    .perfect-scrollbar-on {
        overflow: hidden !important;
    }
}

.dropdownitemclass {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.fa-button-class {
    font-size: 20px;
}

.disabled {
    position: relative;
}

.disabled:after {
    content: "";
    position: absolute;
    width: 100%;
    height: inherit;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.progress {
    background-color: #e9ecef;
    border-radius: 0.25rem;
    display: flex;
    font-size: 0.9rem;
    height: 1.2rem;
    margin-top: 1rem;
    overflow: hidden;
}

.progress-bar {
    align-items: center;
    background-color: rgb(0, 136, 255);
    color: #fff;
    display: flex;
    justify-content: center;
}

.table-responsive {
    overflow-x: hidden;
    z-index: 1;
    position: relative;
}

.format-pre pre {
    text-align: left;
    /* background: #49483e;
    color: #f7f7f7; 
    padding: 10px;
    font-size: 14px;*/
}

.format-pre1 {
    text-align: left;
    width: 800px !important;
}

.format-pre2 {
    text-align: left;
    width: 600px !important;
}

.swal2-html-container {
    text-align: left;
}

.attachment-btn {
    /* width: 136px !important; */
    height: 60px !important;
    width: 212px !important;
    font-size: 1rem !important;
    line-height: 1 !important;
    justify-content: center !important
}

.addtrans-btn {
    height: 115px !important;
    width: 250px !important;
    font-size: 1rem !important;
    line-height: 1 !important;
    justify-content: center !important
}

.attachment-icon {
    font-size: 1.2rem !important;
    line-height: 1 !important;
}

.upload-btn-1  {
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 1rem !important;
    border-color: #2fabe1 !important;
    background-color: #e1f4fb !important;
    border-radius: 4 !important;
    text-transform: capitalize !important;
    height: 50px !important;
    margin-right: 5px;
    margin-left: 5px;
    width: auto !important;
    line-height: 1 !important;
    color: black !important;
}

.upload-btn-2  {
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 1rem !important;
    border-color: #2fabe1 !important;
    background-color: white !important;
    border-radius: 4 !important;
    text-transform: capitalize !important;
    height: 50px !important;
    margin-right: 5px;
    margin-left: 5px;
    width: auto !important;
    line-height: 1 !important;
    color: black !important;
    font-weight: 500 !important;
}

.upload-Audio-formats-text {
    font-size: small;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
    width: 90% !important;
    text-align: center !important;
}

.mat-chip {
    justify-content: space-between;
    background-color: #ccffc4 !important;
    border-color: #2fe167 !important;
    border-width: 1;
    color: rgb(38, 38, 38);
    font-size: 12px !important;
}

.mat-radio-button~.mat-radio-button {
    margin-left: 16px;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #2CABE3 !important;
}

.removetoppadding {
    margin-top: -20px;
}

.removetopmarginforlistbox {
    margin-top: 15px;
}

.jqx-notification-container {
    padding-top: 5px !important;
    position: fixed;
    height: auto;
    width: auto;
    pointer-events: none;
    z-index: 99999 !important;
}

.mat-autocomplete-panel {
    min-width: 112px;
    max-width: 280px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    max-width: none;
    max-height: 256px;
    position: relative;
    width: auto !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.jqx-tooltip-text {
    text-align: left;
}

.jqx-grid-cell-pinned {
    background-color: white;
}

.sidebar .sidebar-wrapper {
    background-color: #2fabe1;
}

.sidebar .nav p {
    color: white;
    font-size: 15px;
}

.sidebar .nav i {
    font-size: 30px;
    font-weight: 300 !important;
}


.sidebar .nav li.active nav li.active>a p {
    color: black;
}

.sidebar-mini .sidebar:hover {
    width: 80px !important;
}

.sidebar-mini .sidebar:hover .sidebar-wrapper {
    width: 80px !important;
}
.sidebar .nav li.active > [data-toggle="collapse"] i {
    color: white;
  }
  .sidebar .sidebar:hover .nav li.active > [data-toggle="collapse"] i {
    color: #2fabe1;
  }
  .sidebar .nav li.active>a i {
    color: #2fabe1;
  }
  
  .sidebar .nav li.active>a p {
      color: black;
  }
  .sidebar .nav .caret {
    margin-top: 23px;
  }
  .sidebar-mini .sidebar:hover .sidebar-wrapper .nav li > a p {
    opacity: 0 !important;
  }

  .sidebar-mini .sidebar:hover .sidebar-wrapper > .nav li > a p, .sidebar-mini .sidebar:hover .sidebar-wrapper > .nav [data-toggle=collapse] ~ div > ul > li > a .sidebar-normal, .sidebar-mini .sidebar:hover .sidebar-wrapper .user .user-info [data-toggle=collapse] ~ div > ul > li > a .sidebar-normal, .sidebar-mini .sidebar:hover .sidebar-wrapper .user .user-info > a > span {
    opacity: 0 !important;  
  }
    .tabMenu{
        font-size: 22px !important;
    }
/* .tabMenu:hover{
    background-color: blue;
} */
.sidebar .nav li:hover > a{
    background-color: white !important;
    border-radius: 12px !important;
}
.sidebar .nav li:hover > a i{
    color: #2fabe1 !important;
}
.sidebar .nav li:hover > a p{
    color: black;
}
.sidebar[data-color=viq-logo-color] li.active > a {
    border-radius: 12px;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.jqx-text-area-element {
    font-size: 1rem !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    color: rgb(52, 52, 52);
    background-color: #ffffff;
    border-color: #d3d3d3 !important;
}
.jqx-action-button {
    background-color: white;
    border: none;
}

.jqx-fill-state-focus {
    border-color: #2fabe1 !important;
}

.jqx-grid-header, .jqx-grid-column-header{
    font-size: 14px !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    background-color: #e1f4fb;
    border-color: #E1F2FA !important;
    color: rgb(60, 60, 60) !important;
}

.jqx-grid-cell{
    font-size: 14px !important;
    font-family:"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    border-color: #E1F2FA !important;
}

.jqx-grid {
    border-radius: 10px !important;
    border-color: #E1F2FA !important;
}

.mat-radio-label {
    color: #757575;
}

.mat-checkbox-label {
    color: #757575;
}

.font-blue-shade {
    color: #187EB1;
}

.font-red-shade {
    color: #ED0202;
}

.font-gray-shade {
    color: #757575;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #ED0202 !important;
}
.mat-error {
    color: #ED0202 !important;
}

.jqx-grid-cell-selected-material {
    color: #0074B3 !important;
}
.nav-pills.nav-pills-info .nav-item .nav-link.active {
    background-color: #187EB1 !important;
}

.hyperlink {
    text-decoration: underline !important;
}
.notification-banner {
    position: relative;
    background-color: white;
    color: #721c24;
    padding: 15px;
    border: 1px solid white;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }
 
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #f50820;
    margin-right: 24%;
    margin-top: -0.2%;
  }
 
  .close-button:hover {
    color: blue;
    ;
  }
  .icon-button {
    background: none;        /* No button background */
    border: none;            /* No button border */
    cursor: pointer;         /* Pointer cursor on hover */
    padding: 0;              /* Remove default padding */
    display: flex;           /* Flex to center the icon */
    align-items: center;     /* Center icon vertically */
    justify-content: center; /* Center icon horizontally */
    margin-left: 72%;
    transform: translateY(-50%);
    z-index: 1001;
    position: fixed;
    right: 0;
    top: 3.0%;
  }
  
  .icon-button mat-icon {
    font-size: 1.5em;        /* Adjust icon size */
    color: black;            /* Icon color */
    margin-top: -90%;
  }
  
  .icon-button:hover mat-icon {
    color: gray;             /* Hover color change */
  }

  .info{
    max-width: unset;
  }
  /* Styles for small screens (less than 768px) */
@media (max-width: 767px) {
    .notification-banner {
        width: 90%; /* Increase width for small screens */
        padding: 8px 1px;
        font-size: 14px; /* Adjust font size if needed */
    }

    .icon-button mat-icon {
        font-size: 1.2em; /* Smaller icon for small screens */
    }
}

/* Styles for large screens (768px and up) */
@media (min-width: 768px) {
    .notification-banner {
        width: 52%; /* Default width for larger screens */
        padding: 10px 2px;
    }

    .icon-button mat-icon {
        font-size: 1.5em; /* Larger icon for larger screens */
    }
    .custom-placeholder .jqx-dropdownlist-content {
        color: black !important; /* Change to your desired color */
      }
}