/* ========================== */
/* START OF MY FILES COMPONENT CSS */
/* ========================== */



// breadcrumb_CSS
.my-files-breadcrumb-list-outer {
    margin-bottom: 20px;
    padding: 0 30px;
}

.my-files-breadcrumb-list {
    ul {
        li {
            display: inline-block;
            margin-right: 10px;
            font-family: $Montserrat;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.25;
            color: #101820;
        }
    }
}

.my-files-breadcrumb-share-btn {
    padding-left: 20px;
}


// breadcrumb_CSS

// my_files_table_CSS
.my-files-data-table-inner {
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px rgba(47, 171, 225, 0.4);
    background-color: #fff;
    margin-bottom: 15px;
    overflow-x: auto;
    &.fileListTable{
        max-height: calc(100vh - 305px);
    }
}

.my-files-data-table-outer {
    padding: 0 30px;

    thead {
        background-color: #eef6f9;

        th {
            font-family: $Montserrat;
            padding: 17px 10px;
            font-size: 13px;
            font-weight: 600;
            line-height: 1.23;
            color: #393f46;
            cursor: auto;
            text-transform: capitalize;

            &.my-files-data-table-owner-th {
                width: 142px;
            }

            &.my-files-data-table-length-th {
                width: 90px;
                ;
            }

            &.my-files-data-table-created-th {
                width: 123px;
            }

            &:first-child {
                padding-left: 20px;
            }

            &.my-files-data-table-checkbox-th {
                width: 50px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }

    }

    tbody {
        tr {
            border-bottom:1px #e2e2e3 solid;
        }

        td {
            padding: 16px 10px;

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 1.14;
                color: #414244;
                .tippy {
                    position: relative;
                    top: -1px;
                    margin-left: 4px;
                    font-size: 12px;
                    color: $PrimaryThemeColor ;
                    cursor: pointer;
                }
            }
        }
    }
}



.my-files-file-type-outer {
    .file-cat {
        .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #f1f7f9;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            margin-right: 20px;
        }

        .file-name {
            a {
                font-size: 12px;
                padding-top: 5px;
                font-weight: 600;
            }
            word-break: break-all;
        }

    }

}


.my-files-file-status {
    p {
        padding-left: 13px;
        position: relative;
        font-weight: 600 !important;

    }

    &.in-progress p {
        color: #ffac20 !important;
    }

    &.in-editing p {
        color: #003e52 !important;
    }

    &.in-completed p {
        color: $AlertGreenColor !important;
    }

    &.in-failed p {
        color: $ThemeRedColor !important;
    }

    p::before {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid;
    }

    &.in-progress p::before {
        border: 1px #ffac20 solid;
        background-color: #ffdfa9;
    }

    &.in-editing p::before {
        border: 1px #003e52 solid;
        background-color: #85cae1;
    }

    &.in-completed p::before {
        border: 1px $AlertGreenColor solid;
        background-color: #d5fc7c;
    }

    &.in-failed p::before {
        border: 1px $ThemeRedColor solid;
        background-color: #ffcbcb;
    }

}


.my-files-table-select-all-checkbox-th .mat-checkbox-frame {
    border-width: 1px;
    border-radius: 3px;
    border-color: #b6b6b6;
}

.my-files-file-label-selector {
    .mat-form-field-appearance-fill .mat-form-field-flex {
        border-radius: 0;
        padding: 0;
        background-color: transparent;
    }

    .mat-form-field-type-mat-native-select .mat-input-element {
        padding: 0;
    }
}

.my-files-table-filter-th,
.my-files-file-menu-option {
    text-align: right;
}

.my-files-table-filter-th {
    i {
        font-size: 18px;
        font-weight: 400;
        color: #8e9598;
    }
}

.my-files-file-menu-option {
    .mat-menu-trigger {
        padding: 0;
    }
}

/* mat-checkbox */

.mat-checkbox {

    /* mat-checkbox-layout */

    .mat-checkbox-layout {
        white-space: normal;
    }

    /* mat-checkbox-inner-container */

    .mat-checkbox-inner-container {
        width: 20px;
        height: 20px;
        margin-top: -1px;

        .mat-ripple-element {
            background-color: $PrimaryThemeColor !important;
        }

        .mat-checkbox-frame {
            border-width: 1px;
            border-radius: 3px;
            border-color: #b6b6b6;
        }

        .mat-checkbox-background {

            .mat-checkbox-checkmark {
                transform: scale(0.75);
            }

        }

    }

    &.mat-checkbox-checked {

        /* mat-checkbox-inner-container */

        .mat-checkbox-inner-container {

            .mat-checkbox-background {
                background-color: $PrimaryThemeColor;
            }

        }

    }

}

.my-files-file-menu-option {
    .my-files-file-menu-inr {
        margin-left: auto;
    }

    .my-files-file-menu-pro-edit-btn {
        max-width: 16px;
    }

    .mat-focus-indicator {
        min-width: 20px;
    }

    .mat-button-wrapper {
        i {
            color: $PrimaryThemeColor;
        }
    }
}


// my_files_table_CSS





/* ========================== */
/* END OF MY FILES COMPONENT CSS */
/* ========================== */


/* ====================== */
/* START OF SHARED WITH ME CSS */
/* ====================== */

/* wraper-sharedwithme-nofiles */

.wraper-sharedwithme-nofiles {

    >.container-fluid {
        padding-top: 20px;
        padding-right: 30px;
        padding-bottom: 40px;
        padding-left: 30px;
    }

    /* sharedwithme-nofiles */

    .sharedwithme-nofiles {
        min-height: calc(100vh - 340px);

        /* sharedwithme-nofiles-item */

        .sharedwithme-nofiles-item {

            .icon {
                margin-bottom: 45px;
            }

            .content {

                >*:last-child {
                    margin-bottom: 0 !important;
                }

                .title {
                    margin-bottom: 8px;
                    font-size: 16px;
                }

                .subtitle {
                    margin-bottom: 10px;
                    font-size: 14px;
                }

            }

        }

    }

}

.my-files-file-label-selector {
    .my-files-file-label-inr {
        .mat-menu-trigger {
            padding: 0 !important;
            &:hover {
                background-color: transparent;
            }
            .mat-button-focus-overlay,
            .mat-ripple {
                display: none;
            }
        }

        .mat-button-wrapper {
            font-family: $Overpass;
            font-weight: 400;
            color: #414244;
            display: flex;
            align-items: center;

            >span {
                margin-left: 10px;

                >i {
                    color: #8e9ea5;
                    font-size: 12px;
                }
            }
        }

        .mat-menu-panel .mat-menu-item i {
            font-size: 140%;
            color: #414244;
        }
    }
}

.myLabelSelectorMatMenu~.cdk-overlay-connected-position-bounding-box {
    .mat-menu-panel {
        .mat-menu-content:not(:empty) {
            padding-bottom: 0;
        }

        .my-files-add-new-label-btn {
            margin-top: 15px;
            border-top: 1px #e0e9ef solid;
            padding: 20px;
            color: #2fabe1;

            i {
                font-size: 100% !important;
                color: #2fabe1 !important;
            }
        }

        .mat-menu-item i {
            font-size: 140%;
        }
    }
}


/* ====================== */
/* END OF SHARED WITH ME CSS */
/* ====================== */

/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */

/* EXTRA LARGE DESKTOP PART */
@media screen and (min-width:1920px) {}

/* EXTRA LARGE DESKTOP PART */
@media screen and (max-width:1919px) {}

/* LARGE DESKTOP PART */
@media screen and (max-width:1400px) {}

/* DESKTOP PART */
@media screen and (max-width:1199px) {}

/* TABLET LANDSCAPE PART */
@media screen and (max-width:991px) {
     /* table-area */
        .my-files-data-table-inner {
            >table{
                width: 1000px !important;
            }
        }
      /* table-area */
}

/* TABLET PROTRAIT PART */
@media screen and (max-width:767px) {

    /* table-area */

    // my_files_table_CSS
    .my-files-data-table-inner {
        &.fileListTable{
            max-height: calc(100vh - 255px);
        }
    }

    .my-files-data-table-outer {
        padding: 0 20px;
        position: relative;

        table {
            width: 100% !important;
            th:first-child,
            th:last-child,
            td:first-child,
            td:last-child {
                padding-left: 10px;
                padding-right: 10px;
            }

            td {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .my-files-table-filter-th i {
                font-size: 15px;
            }
        }

        .my-files-breadcrumb-list-outer {
            padding: 0;
            margin-bottom: 0;

            li {
                font-size: 12px;
                margin-right: 4px;
                line-height: 0;
                vertical-align: middle;
            }
        }

        .my-files-breadcrumb-share-btn {
            padding-left: 5px;
        }
    }

    .my-files-media-checkbox-col {
        .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #f1f7f9;
            width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 0 15px 0 10px;
        }

        .my-files-file-selct-bx-outer {
            .mat-checkbox {
                .mat-checkbox-inner-container {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .my-files-media-details-col {
        .file-cat {
            .file-name {
                p {
                    font-size: 12px;
                    font-weight: 700;
                    color: #414244;
                    margin-bottom: 5px;
                }
            }

            .file-date-time {

                .my-files-file-length,
                .my-files-file-create-on {
                    margin-bottom: 15px;

                    p {
                        font-size: 11px;
                        line-height: 12px;
                    }
                }

                .my-files-file-length {
                    padding-right: 5px;
                    margin-right: 5px;
                    border-right: 1px #979797 solid;
                }
            }

            .my-files-file-status {
                p {
                    font-size: 12px;
                    padding-left: 12px;

                    &::before {
                        top: 0;
                    }
                }
            }
        }
    }

    .top-row {
        position: relative;

        .my-files-file-type-outer {
            margin-top: 5px;
        }

        .my-files-table-filter-col {
            position: absolute;
            top: 0;
            right: -8px;

            .mat-menu-trigger {
                line-height: 0 !important;

                .mat-ripple {
                    display: none;
                }
            }
        }
    }

    .bottom-row {
        padding: 15px 0 0 0;
        margin: 15px 12px 0 12px;
        border-top: solid 0.5px #979797;

        .my-files-file-owner-name,
        .my-files-file-label-selector {
            .title {
                p {
                    font-family: $Montserrat;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                    margin-bottom: 3px;
                    text-transform: capitalize;
                    color: $PrimaryThemeColor;
                }
            }

        }

        .my-files-file-owner-name {
            p {
                font-size: 13px;
                color: #222528;
            }
        }

        .my-files-file-label-inr {
            line-height: 0;

            .mat-menu-trigger {
                font-size: 13px;
                line-height: 16px !important;

                .mat-button-wrapper {
                    color: #222528;

                    >span {
                        margin-left: 4px;
                    }
                }
            }
        }
    }
    ::ng-deep .add-new-backdrop ~ .cdk-overlay-connected-position-bounding-box .mat-menu-panel .mat-menu-item {
        padding: 16px 25px 14px 25px;
        font-size: 14px;
      }
 /* table-area */


}

/* HIGH DEFINITION PHONE PART */
@media screen and (max-width:575px) {
     /* table-area */
    .my-files-file-type-outer {
        .file-cat{
            max-width: 145px;
        }
    }
     /* table-area */
}

/* SMART PHONE LANDSCAPE PART */
@media screen and (max-width:479px) {}

/* SMART PHONE LANDSCAPE PART */
@media (max-width:812px) and (orientation:landscape) {}

/* ========================== */
/* END OF RESPONSIVE CSS */
/* ========================== */