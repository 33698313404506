/*Rounded Corners*/
/*top-left rounded Corners*/
.jqx-rc-tl-bootstrap {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
}
/*top-right rounded Corners*/
.jqx-rc-tr-bootstrap {
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
}
/*bottom-left rounded Corners*/
.jqx-rc-bl-bootstrap {
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
/*bottom-right rounded Corners*/
.jqx-rc-br-bootstrap {
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
/*top rounded Corners*/
.jqx-rc-t-bootstrap {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
}
/*bottom rounded Corners*/
.jqx-rc-b-bootstrap {
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
/*right rounded Corners*/
.jqx-rc-r-bootstrap {
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
/*left rounded Corners*/
.jqx-rc-l-bootstrap {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
/*all rounded Corners*/
.jqx-rc-all-bootstrap {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.jqx-widget-bootstrap, .jqx-widget-header-bootstrap, .jqx-fill-state-normal-bootstrap,
.jqx-widget-content-bootstrap, .jqx-fill-state-hover-bootstrap, .jqx-fill-state-pressed-bootstrap {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-transition: box-shadow linear 0.2s;
    -moz-transition: box-shadow linear 0.1s;
    -o-transition: box-shadow linear 0.1s;
    transition: box-shadow linear 0.1s;
}

.jqx-widget-content-bootstrap {
    background-color: #ffffff;
    border-color: #e6e6e6;
}

.jqx-widget-header-bootstrap {
    background-color: #f5f5f5;
    border-color: #cccccc;
}

.jqx-fill-state-normal-bootstrap {
    color: #333333;
    background-color: #ffffff;
    border-color: #cccccc;
}

.jqx-button-bootstrap {
     padding: 4px 12px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    *zoom: 1;
    color: #333333;
    background-color: #ffffff;
    border-color: #cccccc;
}

.jqx-combobox-multi-item-bootstrap {
    padding: 1px 3px;
}

.jqx-fill-state-hover-bootstrap, .jqx-fill-state-pressed-bootstrap {
    color: #333333;
    background-color: #ebebeb;
    border-color: #adadad;
}

.jqx-fill-state-hover-bootstrap, .jqx-fill-state-focus-bootstrap {
    color: #333333;
    text-decoration: none;
}

.jqx-fill-state-pressed-bootstrap {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.jqx-grid-cell-bootstrap {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.jqx-grid-column-menubutton-bootstrap {
    background-color: transparent;
}

.jqx-calendar-row-header-bootstrap, .jqx-calendar-top-left-header-bootstrap {
    background-color: #f0f0f0;
    border: 0px solid #f2f2f2;
}

.jqx-calendar-column-header-bootstrap {
    background-color: #FFF;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #e9e9e9;
}

.jqx-expander-header-bootstrap {
    background: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #e6e6e6;
}

.jqx-widget-bootstrap, .jqx-widget-header-bootstrap, .jqx-widget-content-bootstrap {
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
}

.jqx-scrollbar-state-normal-bootstrap {
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
}

.jqx-scrollbar-thumb-state-normal-bootstrap, .jqx-scrollbar-thumb-state-normal-horizontal-bootstrap {
    background: #f5f5f5;
    border-color: #b3b3b3;
}

.jqx-scrollbar-thumb-state-hover-bootstrap, .jqx-scrollbar-thumb-state-hover-horizontal-bootstrap {
    background: #e6e6e6;
    border-color: #b3b3b3;
}

.jqx-progressbar-bootstrap {
    background: #f7f7f7 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.jqx-progressbar-value-bootstrap, .jqx-splitter-collapse-button-horizontal-bootstrap {
    background-color: #428bca;
}

.jqx-splitter-collapse-button-vertical-bootstrap, .jqx-progressbar-value-vertical-bootstrap {
    background-color: #428bca;
}

.jqx-scrollbar-thumb-state-pressed-bootstrap, .jqx-splitter-splitbar-vertical-bootstrap, .jqx-splitter-splitbar-horizontal-bootstrap, .jqx-scrollbar-thumb-state-pressed-horizontal-bootstrap {
    background: #d9d9d9;
    border-color: #b3b3b3;
}

.jqx-grid-column-sortdescbutton-bootstrap, jqx-grid-column-filterbutton-bootstrap, .jqx-grid-column-sortascbutton-bootstrap {
    background-color: transparent;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #cccccc;
}

.jqx-slider-rangebar-bootstrap {
    background: #cccccc;
}

.jqx-menu-vertical-bootstrap {
    background: #ffffff;
    filter: none;
}

.jqx-checkbox-check-checked-bootstrap {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAcklEQVQY02NgwA/YoJgoEA/Es4DYgJBCJSBeD8SboRinBiYg7kZS2IosyQ/Eakh8LySFq4FYHFlxGRBvBOJYqMRqJMU+yApNkSRAeC0Sux3dfSCTetE0wKyXxOWhMKhTYIr9CAUXyJMzgLgBagBBgDPGAI2LGdNt0T1AAAAAAElFTkSuQmCC');
}

.jqx-combobox-content-bootstrap, .jqx-input-bootstrap {
}

.jqx-combobox-content-bootstrap {
    border-color: #cccccc;
}

.jqx-fill-state-focus-bootstrap, .jqx-combobox-content-focus-bootstrap, .jqx-numberinput-focus-bootstrap {
    outline: none;
    border-color: #b3b3b3;
}

.jqx-grid-bottomright-bootstrap, .jqx-panel-bottomright-bootstrap, .jqx-listbox-bottomright-bootstrap {
    background-color: #efefef;
}

.jqx-tabs-title-bootstrap,
.jqx-tabs-title-bottom-bootstrap, .jqx-expander-header-bootstrap {
    color: #0088cc !important;
}

    .jqx-expander-header-bootstrap:hover:not(.jqx-fill-state-disabled-bootstrap) {
        color: #005580 !important;
        cursor: pointer;
    }

.jqx-tabs-title-hover-top-bootstrap,
.jqx-tabs-title-hover-bottom-bootstrap {
    color: #005580 !important;
    background: #eee;
    border-color: #eee;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

.jqx-tabs-title-selected-top-bootstrap, .jqx-tabs-selection-tracker-top-bootstrap {
    border-color: #ddd;
    border-bottom: 1px solid #fff;
    filter: none;
    color: #555555 !important;
    background: #fff;
    box-shadow: none;
}

.jqx-tabs-title-selected-bottom-bootstrap, .jqx-tabs-selection-tracker-bottom-bootstrap {
    border-color: #ddd;
    border-top: 1px solid #fff;
    filter: none;
    color: #555555 !important;
    background: #fff;
    box-shadow: none;
}

.jqx-tabs-header-bootstrap {
    background: #fff;
    border-color: #ddd;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.jqx-tabs-bootstrap {
    border-color: #ddd;
}

.jqx-window-bootstrap, .jqx-tooltip-bootstrap {
    box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0,0,0,0.15);
}

.jqx-docking-bootstrap .jqx-window-bootstrap {
    box-shadow: none;
}

.jqx-docking-panel-bootstrap .jqx-window-bootstrap {
    box-shadow: none;
}

.jqx-radiobutton-bootstrap {
    background-repeat: no-repeat;
    background: none;
}

.jqx-radiobutton-bootstrap-bootstrap, .jqx-radiobutton-hover-bootstrap {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    background-repeat: no-repeat;
}

.jqx-radiobutton-check-checked-bootstrap {
    filter: none;
    background: #666;
    background-repeat: no-repeat;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-radiobutton-check-indeterminate-bootstrap {
    filter: none;
    background: #999;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-radiobutton-check-indeterminate-disabled-bootstrap {
    filter: none;
    background: #999;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-slider-track-horizontal-bootstrap, .jqx-slider-track-vertical-bootstrap {
    border-color: #cccccc;
    background: #f0f0f0;
}

.jqx-slider-button-bootstrap {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-fill-state-normal-bootstrap.jqx-primary {
    color: #ffffff;
    background-color: #428bca;
    border-color: #357ebd;
}

.jqx-fill-state-hover-bootstrap.jqx-primary {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
}

.jqx-fill-state-pressed-bootstrap.jqx-primary {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
}

.jqx-fill-state-normal-bootstrap.jqx-warning {
    color: #ffffff;
    background-color: #f0ad4e;
    border-color: #eea236;
}

.jqx-fill-state-hover-bootstrap.jqx-warning {
    color: #ffffff;
    background-color: #ed9c28;
    border-color: #d58512;
}

.jqx-fill-state-pressed-bootstrap.jqx-warning {
    color: #ffffff;
    background-color: #ed9c28;
    border-color: #d58512;
}

.jqx-fill-state-normal-bootstrap.jqx-danger {
    color: #ffffff;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.jqx-fill-state-hover-bootstrap.jqx-danger {
    color: #ffffff;
    background-color: #d2322d;
    border-color: #ac2925;
}

.jqx-fill-state-pressed-bootstrap.jqx-danger {
    color: #ffffff;
    background-color: #d2322d;
    border-color: #ac2925;
}

.jqx-fill-state-normal-bootstrap.jqx-success {
    color: #ffffff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.jqx-fill-state-hover-bootstrap.jqx-success {
    color: #ffffff;
    background-color: #47a447;
    border-color: #398439;
}

.jqx-fill-state-pressed-bootstrap.jqx-success {
    color: #ffffff;
    background-color: #47a447;
    border-color: #398439;
}

.jqx-fill-state-normal-bootstrap.jqx-info {
    color: #ffffff;
    background-color: #5bc0de;
    border-color: #46b8da;
}

.jqx-fill-state-hover-bootstrap.jqx-info {
    color: #ffffff;
    background-color: #39b3d7;
    border-color: #269abc;
}

.jqx-fill-state-pressed-bootstrap.jqx-info {
    color: #ffffff;
    background-color: #39b3d7;
    border-color: #269abc;
}

.jqx-fill-state-normal-bootstrap.jqx-inverse {
    color: #ffffff;
    background-color: #222222;
    border-color: #080808;
}

.jqx-fill-state-hover-bootstrap.jqx-inverse {
    color: #ffffff;
    background-color: #080808;
}

.jqx-fill-state-pressed-bootstrap.jqx-inverse {
    color: #ffffff;
    background-color: #080808;
}

/*applied to a list item when the item is selected.*/
.jqx-listitem-state-hover-bootstrap, .jqx-menu-item-hover-bootstrap, .jqx-tree-item-hover-bootstrap, .jqx-calendar-cell-hover-bootstrap, .jqx-grid-cell-hover-bootstrap,
.jqx-menu-vertical-bootstrap .jqx-menu-item-top-hover-bootstrap, .jqx-input-popup-bootstrap .jqx-fill-state-hover-bootstrap,
.jqx-input-button-header-bootstrap, .jqx-input-popup-bootstrap .jqx-fill-state-pressed-bootstrap,
.jqx-input-button-header-bootstrap {
    color: #ffffff !important;
    color: #333333 !important;
    background-color: #ebebeb !important;
    border-color: #ebebeb !important;
    outline: 0;
}
.jqx-grid-cell-hover-bootstrap {
    border-color: #ccc !important;
}
.jqx-listitem-state-selected-bootstrap, .jqx-menu-item-selected-bootstrap, .jqx-tree-item-selected-bootstrap, .jqx-calendar-cell-selected-bootstrap, .jqx-grid-cell-selected-bootstrap,
.jqx-menu-vertical-bootstrap .jqx-menu-item-top-selected-bootstrap, .jqx-grid-selectionarea-bootstrap {
    color: #ffffff !important;
    background-color: #428bca !important;
    border-color: #357ebd !important;
}
/*top rounded Corners*/
.jqx-rc-t-expanded-bootstrap {
    -moz-border-radius-topleft: 4px !important;
    -webkit-border-top-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
    -moz-border-radius-topright: 4px !important;
    -webkit-border-top-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
}
/*bottom rounded Corners*/
.jqx-rc-b-expanded-bootstrap {
    -moz-border-radius-bottomleft: 4px !important;
    -webkit-border-bottom-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    -moz-border-radius-bottomright: 4px !important;
    -webkit-border-bottom-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.jqx-popup-bootstrap.jqx-listbox-bootstrap, .jqx-popup-bootstrap.jqx-calendar-bootstrap {
    top: 2px !important;
    position: relative;
}

.jqx-popup-up-bootstrap.jqx-listbox-bootstrap, .jqx-popup-up-bootstrap.jqx-calendar-bootstrap {
    top: 23px !important;
    position: relative;
}

.jqx-menu-popup-bootstrap > div:first-child {
    padding-bottom: 15px !important;
    padding-right: 15px !important;
}

.jqx-menu-dropdown-bootstrap, .jqx-popup-bootstrap.jqx-dropdownbutton-popup-bootstrap {
    margin-top: 2px !important;
}

.jqx-popup-bootstrap {
    border: 1px solid #ccc;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
    -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    -webkit-background-clip: padding-box !important;
    -moz-background-clip: padding !important;
    background-clip: padding-box !important;
}

.jqx-widget-bootstrap .jqx-grid-cell-bootstrap, .jqx-widget-bootstrap .jqx-grid-column-header-bootstrap, .jqx-widget-bootstrap .jqx-grid-group-cell {
    border-color: #ccc;
}

.jqx-widget-bootstrap .jqx-grid-column-menubutton-bootstrap, .jqx-widget-bootstrap .jqx-grid-column-sortascbutton-bootstrap, .jqx-widget-bootstrap .jqx-grid-column-sortdescbutton-bootstrap, .jqx-widget .jqx-grid-column-filterbutton-bootstrap {
    border-color: #ccc;
}

.jqx-grid-column-sortascbutton-bootstrap, .jqx-expander-arrow-bottom-bootstrap, .jqx-window-collapse-button-bootstrap, .jqx-menu-item-arrow-up-bootstrap, .jqx-menu-item-arrow-up-selected-bootstrap, .jqx-menu-item-arrow-top-up-bootstrap, .jqx-icon-arrow-up-bootstrap, .jqx-icon-arrow-up-hover-bootstrap, .jqx-icon-arrow-up-selected-bootstrap {
    background-image: url('images/icon-up.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-widget-bootstrap .jqx-grid-group-expand-bootstrap, .jqx-grid-group-expand-bootstrap, .jqx-grid-column-menubutton-bootstrap, .jqx-grid-column-sortdescbutton-bootstrap, .jqx-expander-arrow-top-bootstrap, .jqx-window-collapse-button-collapsed-bootstrap, .jqx-menu-item-arrow-down-bootstrap, .jqx-menu-item-arrow-down-selected-bootstrap, .jqx-menu-item-arrow-down-bootstrap, .jqx-icon-arrow-down-bootstrap, .jqx-icon-arrow-down-hover-bootstrap, .jqx-icon-arrow-down-selected-bootstrap {
    background-image: url('images/icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tabs-arrow-left-bootstrap, .jqx-menu-item-arrow-left-selected-bootstrap, .jqx-menu-item-arrow-top-left, .jqx-icon-arrow-left-bootstrap, .jqx-icon-arrow-down-left-bootstrap, .jqx-icon-arrow-left-selected-bootstrap {
    background-image: url('images/icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-widget-bootstrap .jqx-grid-group-collapse-bootstrap, .jqx-grid-group-collapse-bootstrap, .jqx-tabs-arrow-right-bootstrap, .jqx-menu-item-arrow-right-selected-bootstrap, .jqx-menu-item-arrow-top-right-bootstrap, .jqx-icon-arrow-right-bootstrap, .jqx-icon-arrow-right-hover-bootstrap, .jqx-icon-arrow-right-selected-bootstrap {
    background-image: url('images/icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tree-item-arrow-collapse-rtl-bootstrap, .jqx-tree-item-arrow-collapse-hover-rtl-bootstrap {
    background-image: url(./images/icon-left.png);
}

.jqx-menu-item-arrow-left-selected-bootstrap {
    background-image: url('images/icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-menu-item-arrow-right-selected-bootstrap {
    background-image: url('images/icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-primary .jqx-grid-column-sortascbutton-bootstrap, .jqx-primary .jqx-expander-arrow-bottom-bootstrap, .jqx-primary .jqx-window-collapse-button-bootstrap, .jqx-primary .jqx-menu-item-arrow-up-bootstrap, .jqx-primary .jqx-menu-item-arrow-up-selected-bootstrap, .jqx-primary .jqx-menu-item-arrow-top-up-bootstrap, .jqx-primary .jqx-icon-arrow-up-bootstrap, .jqx-primary .jqx-icon-arrow-up-hover-bootstrap, .jqx-primary .jqx-icon-arrow-up-selected-bootstrap {
    background-image: url('images/icon-up-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-primary .jqx-widget-bootstrap .jqx-primary .jqx-grid-group-expand-bootstrap, .jqx-primary .jqx-grid-group-expand-bootstrap, .jqx-primary .jqx-grid-column-menubutton-bootstrap, .jqx-primary .jqx-grid-column-sortdescbutton-bootstrap, .jqx-primary .jqx-expander-arrow-top-bootstrap, .jqx-primary .jqx-window-collapse-button-collapsed-bootstrap, .jqx-primary .jqx-menu-item-arrow-down-bootstrap, .jqx-primary .jqx-menu-item-arrow-down-selected-bootstrap, .jqx-primary .jqx-menu-item-arrow-down-bootstrap, .jqx-primary .jqx-icon-arrow-down-bootstrap, .jqx-primary .jqx-icon-arrow-down-hover-bootstrap, .jqx-primary .jqx-icon-arrow-down-selected-bootstrap {
    background-image: url('images/icon-down-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-primary .jqx-tabs-arrow-left-bootstrap, .jqx-primary .jqx-menu-item-arrow-left-selected-bootstrap, .jqx-primary .jqx-menu-item-arrow-top-left, .jqx-primary .jqx-icon-arrow-left-bootstrap, .jqx-primary .jqx-icon-arrow-down-left-bootstrap, .jqx-primary .jqx-icon-arrow-left-selected-bootstrap {
    background-image: url('images/icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-primary .jqx-widget-bootstrap .jqx-primary .jqx-grid-group-collapse-bootstrap, .jqx-primary .jqx-grid-group-collapse-bootstrap, .jqx-primary .jqx-tabs-arrow-right-bootstrap, .jqx-primary .jqx-menu-item-arrow-right-selected-bootstrap, .jqx-primary .jqx-menu-item-arrow-top-right-bootstrap, .jqx-primary .jqx-icon-arrow-right-bootstrap, .jqx-primary .jqx-icon-arrow-right-hover-bootstrap, .jqx-primary .jqx-icon-arrow-right-selected-bootstrap {
    background-image: url('images/icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-primary .jqx-window-close-button-bootstrap, .jqx-primary .jqx-icon-close-bootstrap, .jqx-primary .jqx-tabs-close-button-bootstrap, .jqx-primary .jqx-tabs-close-button-hover-bootstrap, .jqx-primary .jqx-tabs-close-button-selected-bootstrap {
    background-image: url(./images/close_white.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-primary .jqx-tree-item-arrow-collapse-rtl-bootstrap, .jqx-primary .jqx-tree-item-arrow-collapse-hover-rtl-bootstrap {
    background-image: url(./images/icon-left.png);
}

.jqx-warning .jqx-grid-column-sortascbutton-bootstrap, .jqx-warning .jqx-expander-arrow-bottom-bootstrap, .jqx-warning .jqx-window-collapse-button-bootstrap, .jqx-warning .jqx-menu-item-arrow-up-bootstrap, .jqx-warning .jqx-menu-item-arrow-up-selected-bootstrap, .jqx-warning .jqx-menu-item-arrow-top-up-bootstrap, .jqx-warning .jqx-icon-arrow-up-bootstrap, .jqx-warning .jqx-icon-arrow-up-hover-bootstrap, .jqx-warning .jqx-icon-arrow-up-selected-bootstrap {
    background-image: url('images/icon-up-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-warning .jqx-widget-bootstrap .jqx-warning .jqx-grid-group-expand-bootstrap, .jqx-warning .jqx-grid-group-expand-bootstrap, .jqx-warning .jqx-grid-column-menubutton-bootstrap, .jqx-warning .jqx-grid-column-sortdescbutton-bootstrap, .jqx-warning .jqx-expander-arrow-top-bootstrap, .jqx-warning .jqx-window-collapse-button-collapsed-bootstrap, .jqx-warning .jqx-menu-item-arrow-down-bootstrap, .jqx-warning .jqx-menu-item-arrow-down-selected-bootstrap, .jqx-warning .jqx-menu-item-arrow-down-bootstrap, .jqx-warning .jqx-icon-arrow-down-bootstrap, .jqx-warning .jqx-icon-arrow-down-hover-bootstrap, .jqx-warning .jqx-icon-arrow-down-selected-bootstrap {
    background-image: url('images/icon-down-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-warning .jqx-tabs-arrow-left-bootstrap, .jqx-warning .jqx-menu-item-arrow-left-selected-bootstrap, .jqx-warning .jqx-menu-item-arrow-top-left, .jqx-warning .jqx-icon-arrow-left-bootstrap, .jqx-warning .jqx-icon-arrow-down-left-bootstrap, .jqx-warning .jqx-icon-arrow-left-selected-bootstrap {
    background-image: url('images/icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-warning .jqx-widget-bootstrap .jqx-warning .jqx-grid-group-collapse-bootstrap, .jqx-warning .jqx-grid-group-collapse-bootstrap, .jqx-warning .jqx-tabs-arrow-right-bootstrap, .jqx-warning .jqx-menu-item-arrow-right-selected-bootstrap, .jqx-warning .jqx-menu-item-arrow-top-right-bootstrap, .jqx-warning .jqx-icon-arrow-right-bootstrap, .jqx-warning .jqx-icon-arrow-right-hover-bootstrap, .jqx-warning .jqx-icon-arrow-right-selected-bootstrap {
    background-image: url('images/icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-warning .jqx-window-close-button-bootstrap, .jqx-warning .jqx-icon-close-bootstrap, .jqx-warning .jqx-tabs-close-button-bootstrap, .jqx-warning .jqx-tabs-close-button-hover-bootstrap, .jqx-warning .jqx-tabs-close-button-selected-bootstrap {
    background-image: url(./images/close_white.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-warning .jqx-tree-item-arrow-collapse-rtl-bootstrap, .jqx-warning .jqx-tree-item-arrow-collapse-hover-rtl-bootstrap {
    background-image: url(./images/icon-left.png);
}

.jqx-danger .jqx-grid-column-sortascbutton-bootstrap, .jqx-danger .jqx-expander-arrow-bottom-bootstrap, .jqx-danger .jqx-window-collapse-button-bootstrap, .jqx-danger .jqx-menu-item-arrow-up-bootstrap, .jqx-danger .jqx-menu-item-arrow-up-selected-bootstrap, .jqx-danger .jqx-menu-item-arrow-top-up-bootstrap, .jqx-danger .jqx-icon-arrow-up-bootstrap, .jqx-danger .jqx-icon-arrow-up-hover-bootstrap, .jqx-danger .jqx-icon-arrow-up-selected-bootstrap {
    background-image: url('images/icon-up-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-danger .jqx-widget-bootstrap .jqx-danger .jqx-grid-group-expand-bootstrap, .jqx-danger .jqx-grid-group-expand-bootstrap, .jqx-danger .jqx-grid-column-menubutton-bootstrap, .jqx-danger .jqx-grid-column-sortdescbutton-bootstrap, .jqx-danger .jqx-expander-arrow-top-bootstrap, .jqx-danger .jqx-window-collapse-button-collapsed-bootstrap, .jqx-danger .jqx-menu-item-arrow-down-bootstrap, .jqx-danger .jqx-menu-item-arrow-down-selected-bootstrap, .jqx-danger .jqx-menu-item-arrow-down-bootstrap, .jqx-danger .jqx-icon-arrow-down-bootstrap, .jqx-danger .jqx-icon-arrow-down-hover-bootstrap, .jqx-danger .jqx-icon-arrow-down-selected-bootstrap {
    background-image: url('images/icon-down-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-danger .jqx-tabs-arrow-left-bootstrap, .jqx-danger .jqx-menu-item-arrow-left-selected-bootstrap, .jqx-danger .jqx-menu-item-arrow-top-left, .jqx-danger .jqx-icon-arrow-left-bootstrap, .jqx-danger .jqx-icon-arrow-down-left-bootstrap, .jqx-danger .jqx-icon-arrow-left-selected-bootstrap {
    background-image: url('images/icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-danger .jqx-widget-bootstrap .jqx-danger .jqx-grid-group-collapse-bootstrap, .jqx-danger .jqx-grid-group-collapse-bootstrap, .jqx-danger .jqx-tabs-arrow-right-bootstrap, .jqx-danger .jqx-menu-item-arrow-right-selected-bootstrap, .jqx-danger .jqx-menu-item-arrow-top-right-bootstrap, .jqx-danger .jqx-icon-arrow-right-bootstrap, .jqx-danger .jqx-icon-arrow-right-hover-bootstrap, .jqx-danger .jqx-icon-arrow-right-selected-bootstrap {
    background-image: url('images/icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-danger .jqx-window-close-button-bootstrap, .jqx-danger .jqx-icon-close-bootstrap, .jqx-danger .jqx-tabs-close-button-bootstrap, .jqx-danger .jqx-tabs-close-button-hover-bootstrap, .jqx-danger .jqx-tabs-close-button-selected-bootstrap {
    background-image: url(./images/close_white.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-danger .jqx-tree-item-arrow-collapse-rtl-bootstrap, .jqx-danger .jqx-tree-item-arrow-collapse-hover-rtl-bootstrap {
    background-image: url(./images/icon-left.png);
}

.jqx-info .jqx-grid-column-sortascbutton-bootstrap, .jqx-info .jqx-expander-arrow-bottom-bootstrap, .jqx-info .jqx-window-collapse-button-bootstrap, .jqx-info .jqx-menu-item-arrow-up-bootstrap, .jqx-info .jqx-menu-item-arrow-up-selected-bootstrap, .jqx-info .jqx-menu-item-arrow-top-up-bootstrap, .jqx-info .jqx-icon-arrow-up-bootstrap, .jqx-info .jqx-icon-arrow-up-hover-bootstrap, .jqx-info .jqx-icon-arrow-up-selected-bootstrap {
    background-image: url('images/icon-up-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-info .jqx-widget-bootstrap .jqx-info .jqx-grid-group-expand-bootstrap, .jqx-info .jqx-grid-group-expand-bootstrap, .jqx-info .jqx-grid-column-menubutton-bootstrap, .jqx-info .jqx-grid-column-sortdescbutton-bootstrap, .jqx-info .jqx-expander-arrow-top-bootstrap, .jqx-info .jqx-window-collapse-button-collapsed-bootstrap, .jqx-info .jqx-menu-item-arrow-down-bootstrap, .jqx-info .jqx-menu-item-arrow-down-selected-bootstrap, .jqx-info .jqx-menu-item-arrow-down-bootstrap, .jqx-info .jqx-icon-arrow-down-bootstrap, .jqx-info .jqx-icon-arrow-down-hover-bootstrap, .jqx-info .jqx-icon-arrow-down-selected-bootstrap {
    background-image: url('images/icon-down-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-info .jqx-tabs-arrow-left-bootstrap, .jqx-info .jqx-menu-item-arrow-left-selected-bootstrap, .jqx-info .jqx-menu-item-arrow-top-left, .jqx-info .jqx-icon-arrow-left-bootstrap, .jqx-info .jqx-icon-arrow-down-left-bootstrap, .jqx-info .jqx-icon-arrow-left-selected-bootstrap {
    background-image: url('images/icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-info .jqx-widget-bootstrap .jqx-info .jqx-grid-group-collapse-bootstrap, .jqx-info .jqx-grid-group-collapse-bootstrap, .jqx-info .jqx-tabs-arrow-right-bootstrap, .jqx-info .jqx-menu-item-arrow-right-selected-bootstrap, .jqx-info .jqx-menu-item-arrow-top-right-bootstrap, .jqx-info .jqx-icon-arrow-right-bootstrap, .jqx-info .jqx-icon-arrow-right-hover-bootstrap, .jqx-info .jqx-icon-arrow-right-selected-bootstrap {
    background-image: url('images/icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-info .jqx-window-close-button-bootstrap, .jqx-info .jqx-icon-close-bootstrap, .jqx-info .jqx-tabs-close-button-bootstrap, .jqx-info .jqx-tabs-close-button-hover-bootstrap, .jqx-info .jqx-tabs-close-button-selected-bootstrap {
    background-image: url(./images/close_white.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-info .jqx-tree-item-arrow-collapse-rtl-bootstrap, .jqx-info .jqx-tree-item-arrow-collapse-hover-rtl-bootstrap {
    background-image: url(./images/icon-left.png);
}

.jqx-success .jqx-grid-column-sortascbutton-bootstrap, .jqx-success .jqx-expander-arrow-bottom-bootstrap, .jqx-success .jqx-window-collapse-button-bootstrap, .jqx-success .jqx-menu-item-arrow-up-bootstrap, .jqx-success .jqx-menu-item-arrow-up-selected-bootstrap, .jqx-success .jqx-menu-item-arrow-top-up-bootstrap, .jqx-success .jqx-icon-arrow-up-bootstrap, .jqx-success .jqx-icon-arrow-up-hover-bootstrap, .jqx-success .jqx-icon-arrow-up-selected-bootstrap {
    background-image: url('images/icon-up-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-success .jqx-widget-bootstrap .jqx-success .jqx-grid-group-expand-bootstrap, .jqx-success .jqx-grid-group-expand-bootstrap, .jqx-success .jqx-grid-column-menubutton-bootstrap, .jqx-success .jqx-grid-column-sortdescbutton-bootstrap, .jqx-success .jqx-expander-arrow-top-bootstrap, .jqx-success .jqx-window-collapse-button-collapsed-bootstrap, .jqx-success .jqx-menu-item-arrow-down-bootstrap, .jqx-success .jqx-menu-item-arrow-down-selected-bootstrap, .jqx-success .jqx-menu-item-arrow-down-bootstrap, .jqx-success .jqx-icon-arrow-down-bootstrap, .jqx-success .jqx-icon-arrow-down-hover-bootstrap, .jqx-success .jqx-icon-arrow-down-selected-bootstrap {
    background-image: url('images/icon-down-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-success .jqx-tabs-arrow-left-bootstrap, .jqx-success .jqx-menu-item-arrow-left-selected-bootstrap, .jqx-success .jqx-menu-item-arrow-top-left, .jqx-success .jqx-icon-arrow-left-bootstrap, .jqx-success .jqx-icon-arrow-down-left-bootstrap, .jqx-success .jqx-icon-arrow-left-selected-bootstrap {
    background-image: url('images/icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-success .jqx-widget-bootstrap .jqx-success .jqx-grid-group-collapse-bootstrap, .jqx-success .jqx-grid-group-collapse-bootstrap, .jqx-success .jqx-tabs-arrow-right-bootstrap, .jqx-success .jqx-menu-item-arrow-right-selected-bootstrap, .jqx-success .jqx-menu-item-arrow-top-right-bootstrap, .jqx-success .jqx-icon-arrow-right-bootstrap, .jqx-success .jqx-icon-arrow-right-hover-bootstrap, .jqx-success .jqx-icon-arrow-right-selected-bootstrap {
    background-image: url('images/icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-success .jqx-window-close-button-bootstrap, .jqx-success .jqx-icon-close-bootstrap, .jqx-success .jqx-tabs-close-button-bootstrap, .jqx-success .jqx-tabs-close-button-hover-bootstrap, .jqx-success .jqx-tabs-close-button-selected-bootstrap {
    background-image: url(./images/close_white.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-success .jqx-tree-item-arrow-collapse-rtl-bootstrap, .jqx-success .jqx-tree-item-arrow-collapse-hover-rtl-bootstrap {
    background-image: url(./images/icon-left.png);
}

.jqx-inverse .jqx-grid-column-sortascbutton-bootstrap, .jqx-inverse .jqx-expander-arrow-bottom-bootstrap, .jqx-inverse .jqx-window-collapse-button-bootstrap, .jqx-inverse .jqx-menu-item-arrow-up-bootstrap, .jqx-inverse .jqx-menu-item-arrow-up-selected-bootstrap, .jqx-inverse .jqx-menu-item-arrow-top-up-bootstrap, .jqx-inverse .jqx-icon-arrow-up-bootstrap, .jqx-inverse .jqx-icon-arrow-up-hover-bootstrap, .jqx-inverse .jqx-icon-arrow-up-selected-bootstrap {
    background-image: url('images/icon-up-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-inverse .jqx-widget-bootstrap .jqx-inverse .jqx-grid-group-expand-bootstrap, .jqx-inverse .jqx-grid-group-expand-bootstrap, .jqx-inverse .jqx-grid-column-menubutton-bootstrap, .jqx-inverse .jqx-grid-column-sortdescbutton-bootstrap, .jqx-inverse .jqx-expander-arrow-top-bootstrap, .jqx-inverse .jqx-window-collapse-button-collapsed-bootstrap, .jqx-inverse .jqx-menu-item-arrow-down-bootstrap, .jqx-inverse .jqx-menu-item-arrow-down-selected-bootstrap, .jqx-inverse .jqx-menu-item-arrow-down-bootstrap, .jqx-inverse .jqx-icon-arrow-down-bootstrap, .jqx-inverse .jqx-icon-arrow-down-hover-bootstrap, .jqx-inverse .jqx-icon-arrow-down-selected-bootstrap {
    background-image: url('images/icon-down-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-inverse .jqx-tabs-arrow-left-bootstrap, .jqx-inverse .jqx-menu-item-arrow-left-selected-bootstrap, .jqx-inverse .jqx-menu-item-arrow-top-left, .jqx-inverse .jqx-icon-arrow-left-bootstrap, .jqx-inverse .jqx-icon-arrow-down-left-bootstrap, .jqx-inverse .jqx-icon-arrow-left-selected-bootstrap {
    background-image: url('images/icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-inverse .jqx-widget-bootstrap .jqx-inverse .jqx-grid-group-collapse-bootstrap, .jqx-inverse .jqx-grid-group-collapse-bootstrap, .jqx-inverse .jqx-tabs-arrow-right-bootstrap, .jqx-inverse .jqx-menu-item-arrow-right-selected-bootstrap, .jqx-inverse .jqx-menu-item-arrow-top-right-bootstrap, .jqx-inverse .jqx-icon-arrow-right-bootstrap, .jqx-inverse .jqx-icon-arrow-right-hover-bootstrap, .jqx-inverse .jqx-icon-arrow-right-selected-bootstrap {
    background-image: url('images/icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-inverse .jqx-window-close-button-bootstrap, .jqx-inverse .jqx-icon-close-bootstrap, .jqx-inverse .jqx-tabs-close-button-bootstrap, .jqx-inverse .jqx-tabs-close-button-hover-bootstrap, .jqx-inverse .jqx-tabs-close-button-selected-bootstrap {
    background-image: url(./images/close_white.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-inverse .jqx-tree-item-arrow-collapse-rtl-bootstrap, .jqx-inverse .jqx-tree-item-arrow-collapse-hover-rtl-bootstrap {
    background-image: url(./images/icon-left.png);
}


.jqx-input-button-content-bootstrap {
    font-size: 10px;
}

.jqx-combobox-content-bootstrap, .jqx-input-bootstrap {
    border-color: #cccccc;
    color: #555555;
    background-color: #ffffff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
    -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
    -o-transition: border linear 0.2s, box-shadow linear 0.2s;
    transition: border linear 0.2s, box-shadow linear 0.2s;
}

.jqx-combobox-content-bootstrap, .jqx-combobox-bootstrap, .jqx-combobox-state-normal-bootstrap {
    border-color: #cccccc;
}

.jqx-combobox-content-focus-bootstrap, .jqx-combobox-state-focus-bootstrap, .jqx-fill-state-focus-bootstrap,
.jqx-numberinput-focus-bootstrap {
    outline: none;
    border-color: rgba(82, 168, 236, 0.8);
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}

.jqx-datetimeinput-content, .jqx-datetimeinput-container {
    overflow: visible !important;
}

input[type="text"].jqx-input-bootstrap, input[type="text"].jqx-widget-content-bootstrap, input[type="textarea"].jqx-widget-content-bootstrap, textarea.jqx-input-bootstrap {
    padding-left: 0px !important;
}

input[type="text"].jqx-input-bootstrap:-moz-placeholder, input[type="text"].jqx-widget-content-bootstrap:-moz-placeholder, input[type="textarea"].jqx-widget-content-bootstrap:-moz-placeholder, textarea.jqx-input-bootstrap:-moz-placeholder {
    color: #999999;
}

input[type="text"].jqx-input-bootstrap:-webkit-input-placeholder, input[type="text"].jqx-widget-content-bootstrap:-webkit-input-placeholder, input[type="textarea"].jqx-widget-content-bootstrap:-webkit-input-placeholder, textarea.jqx-input-bootstrap:-webkit-input-placeholder {
    color: #999999;
}

input[type="text"].jqx-input-bootstrap:-ms-input-placeholder, input[type="text"].jqx-widget-content-bootstrap:-ms-input-placeholder, input[type="textarea"].jqx-widget-content-bootstrap:-ms-input-placeholder, textarea.jqx-input-bootstrap:-ms-input-placeholder {
    color: #999999;
}

input[type="password"].jqx-input-bootstrap, input[type="password"].jqx-widget-content-bootstrap, input[type="textarea"].jqx-widget-content-bootstrap, textarea.jqx-input-bootstrap {
    padding-left: 0px !important;
}

input[type="password"].jqx-input-bootstrap:-moz-placeholder, input[type="password"].jqx-widget-content-bootstrap:-moz-placeholder, input[type="textarea"].jqx-widget-content-bootstrap:-moz-placeholder, textarea.jqx-input-bootstrap:-moz-placeholder {
    color: #999999;
}

input[type="password"].jqx-input-bootstrap:-webkit-input-placeholder, input[type="password"].jqx-widget-content-bootstrap:-webkit-input-placeholder, input[type="textarea"].jqx-widget-content-bootstrap:-webkit-input-placeholder, textarea.jqx-input-bootstrap:-webkit-input-placeholder {
    color: #999999;
}

input[type="password"].jqx-input-bootstrap:-ms-input-placeholder, input[type="password"].jqx-widget-content-bootstrap:-ms-input-placeholder, input[type="textarea"].jqx-widget-content-bootstrap:-ms-input-placeholder, textarea.jqx-input-bootstrap:-ms-input-placeholder {
    color: #999999;
}

.jqx-dropdownlist-state-normal-bootstrap.jqx-fill-state-focus-bootstrap, .jqx-listbox-bootstrap.jqx-fill-state-focus-bootstrap {
    outline: none;
    border-color: #b2b2b2;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.jqx-dropdownlist-state-normal-bootstrap.jqx-fill-state-focus-bootstrap.jqx-primary {
    border-color: #0044cc #0044cc #002a80;
}

.jqx-dropdownlist-state-normal-bootstrap.jqx-fill-state-focus-bootstrap.jqx-warning {
    border-color: #f89406 #f89406 #ad6704;
}

.jqx-dropdownlist-state-normal-bootstrap.jqx-fill-state-focus-bootstrap.jqx-danger {
    border-color: #bd362f #bd362f #802420;
}

.jqx-dropdownlist-state-normal-bootstrap.jqx-fill-state-focus-bootstrap.jqx-success {
    border-color: #51a351 #51a351 #387038;
}

.jqx-dropdownlist-state-normal-bootstrap.jqx-fill-state-focus-bootstrap.jqx-info {
    border-color: #2f96b4 #2f96b4 #1f6377;
}

.jqx-dropdownlist-state-normal-bootstrap.jqx-fill-state-focus-bootstrap.jqx-inverse {
    border-color: #222222 #222222 #000000;
}

.jqx-popup-bootstrap.jqx-fill-state-focus-bootstrap {
    border: 1px solid #ccc;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
    -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.jqx-expander-content-bootstrap.jqx-fill-state-focus-bootstrap {
    z-index: 20;
}

.jqx-slider-rangebar-bootstrap {
    border-color: #428bca;
    background: #428bca;
}

.jqx-slider-button-bootstrap {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    padding: 3px;
}

.jqx-grid-cell-bootstrap.jqx-grid-cell-selected-bootstrap > .jqx-grid-group-expand-bootstrap {
    background-image: url('images/icon-down-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-bootstrap.jqx-grid-cell-selected-bootstrap > .jqx-grid-group-collapse-bootstrap {
    background-image: url('images/icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-bootstrap.jqx-grid-cell-selected-bootstrap > .jqx-grid-group-collapse-rtl-bootstrap {
    background-image: url('images/icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-bootstrap.jqx-grid-cell-selected-bootstrap > .jqx-grid-group-expand-rtl-bootstrap {
    background-image: url('images/icon-down-white.png');
    background-repeat: no-repeat;
    background-position: center;
}
.jqx-layout-bootstrap
{
    background-color: #cccccc;
}
 /*applied to the timepicker*/
.jqx-svg-picker-bootstrap:hover {
	background-color: rgb(248, 248, 248);
}