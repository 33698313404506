/* ========================== */
/* START OF PROFILE CSS */
/* ========================== */

/* update-profile-menu-css */

.update-prfl-mnu-outer {

    .container-fluid {
        padding: 20px 30px 30px;

        .update-prfl-mnu-inr {
            ul {
                display: inline-flex;
                align-items: center;
                border-bottom: 1px #cccccc solid;

                >li {
                    margin-right: 60px;

                    &:last-child {
                        margin-right: 0;
                    }

                    >a {
                        display: inline-block;
                        font-family: $Montserrat;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 1.43;
                        color: #101820;
                        padding-bottom: 15px;
                        position: relative;
                        transition: all 0.3s ease-in-out;

                        &::before {
                            content: "";
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            right: 0;
                            width: 100%;
                            height: 2px;
                            transform: scaleX(0);
                            background-color: #2fabe1;
                            transition: all 0.3s ease-in-out;
                        }
                    }

                    &.current-menu-item {
                        a {
                            color: #2fabe1;

                            &::before {
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* updte-prfl-main-outer */

.updte-prfl-main-outer {

    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }

    /* updte-prfl-main */

    .updte-prfl-main{

        /* updte-prfl-main-left-menu-outer */

        .updte-prfl-main-left-menu-outer{
            width:300px;
            min-width:300px;
            margin-right:40px;

            a{
                display: flex;
                font-family: $Montserrat;
                font-size: 12px;
                font-weight: 700;
                line-height: 1.33;
                color: #393f46;
                padding: 7px 15px;
                background-color: transparent;
                transition: all 0.4s ease-in-out;
                max-width: 200px;
                min-width: 200px;
                border-radius: 30px;
                margin-bottom: 25px;
        
                &:last-child {
                    margin-bottom: 0;
                }
        
                &.current-menu-item {
                    background-color: #e3f0f5;
                }
                
            }

        }

        /* updte-prfl-main-right-pnl-bx-outer */

        .updte-prfl-main-right-pnl-bx-outer{
        }

    }

}

/* ========================== */
/* END OF PROFILE CSS */
/* ========================== */

/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */

/* EXTRA LARGE DESKTOP PART */
@media screen and (min-width:1920px){
}

/* EXTRA LARGE DESKTOP PART */
@media screen and (max-width:1919px){
}

/* LARGE DESKTOP PART */
@media screen and (max-width:1400px){
}

/* DESKTOP PART */
@media screen and (max-width:1199px){
}

/* TABLET LANDSCAPE PART */
@media screen and (max-width:991px){

    /* ========================== */
    /* START OF PROFILE CSS */
    /* ========================== */

    /* update-profile-menu-css */

    .update-prfl-mnu-outer {
        .container-fluid {
            padding: 10px 20px 20px 20px;
        }
    }

    /* updte-prfl-main-outer */

    .updte-prfl-main-outer {

        .container-fluid {
            padding-left: 20px;
            padding-right: 20px;
        }

        /* updte-prfl-main */

        .updte-prfl-main{

            /* updte-prfl-main-left-menu-outer */

            .updte-prfl-main-left-menu-outer{
                width: 200px;
                min-width: 200px;
            }

        }

    }

    /* ========================== */
    /* END OF PROFILE CSS */
    /* ========================== */

}

/* TABLET PROTRAIT PART */
@media screen and (max-width:767px){

    /* ========================== */
    /* START OF PROFILE CSS */
    /* ========================== */

    /* update-profile-menu-css */

    .update-prfl-mnu-outer {
        .container-fluid {
            .update-prfl-mnu-inr{
                overflow-x: auto;
                >ul{
                    width: 500px;
                    >li{
                        margin-right: 30px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    /* updte-prfl-main-outer */

    .updte-prfl-main-outer {

        /* updte-prfl-main */

        .updte-prfl-main{

            /* updte-prfl-main-left-menu-outer */

            .updte-prfl-main-left-menu-outer{
                width:auto;
                min-width:initial;
                margin-right:0;
                margin-bottom:25px;
                display:flex;
                white-space:nowrap;
                overflow-x:scroll;

                a{
                    max-width:initial;
                    min-width:initial;
                    margin-right:10px;
                    margin-bottom:0;
            
                    &:last-child {
                        margin-right: 0;
                    }
                    
                }

            }

        }

    }

    /* ========================== */
    /* END OF PROFILE CSS */
    /* ========================== */

}

/* HIGH DEFINITION PHONE PART */
@media screen and (max-width:575px){
}

/* SMART PHONE LANDSCAPE PART */
@media screen and (max-width:479px){
}

/* SMART PHONE LANDSCAPE PART */
@media (max-width:812px) and (orientation:landscape){
}

/* ========================== */
/* END OF RESPONSIVE CSS */
/* ========================== */
