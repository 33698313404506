@import '@angular/material/theming';
@include mat-core();

@mixin mat-option-theme($theme) {
    // Extract the palettes you need from the theme definition.

    // Define any styles affected by the theme.
    .mat-option:hover {
        // Use mat-color to extract individual colors from a palette.
        background-color: #2CABE2 !important;
        background: #2CABE2 !important;
        box-shadow: 0 1px 4px 0px #2CABE2, 0 0px 0px -5px #2CABE2 !important;
    }

    .mat-option:focus:hover {
        // Use mat-color to extract individual colors from a palette.
        background-color: #2CABE2 !important;
        background: #2CABE2 !important;
        box-shadow: 0 1px 4px 0px #2CABE2, 0 0px 0px -5px #2CABE2 !important;
    }

    .mat-option .active{
        background-color: #2CABE2 !important;
        box-shadow: 0 1px 4px 0px #2CABE2, 0 0px 0px -5px #2CABE2 !important;
    }

    .mat-option.mat-selected 
    .mat-option.mat-selected:hover{
        background-color: #2CABE2 !important;
    }

    .mat-option.mat-selected:not(.mat-option-multiple){
        background-color: #2CABE2 !important;
        box-shadow: 0 1px 4px 0px #2CABE2, 0 0px 0px -5px #2CABE2 !important;
    }
}

/* ======== Angular material custom themes ======== */ 

$primary: mat-palette($mat-light-blue);
$accent: mat-palette($mat-light-blue, 100, 500, A100);
$warn: mat-palette($mat-red);

$my-custom-theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($my-custom-theme);
@include mat-option-theme($my-custom-theme);
