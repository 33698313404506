/* ========================== */
/* START OF RESET CSS */
/* ========================== */

*{
	/*margin:0 auto;*/
	text-decoration:none !important;
	outline:none !important;

	&::-webkit-scrollbar{
		width:4px;
		height:4px;
	}
	   
	&::-webkit-scrollbar-track{
		background-color:rgba(0,0,0,0);
		border-radius:5px;
	}
	   
	&::-webkit-scrollbar-thumb{
		/*background-color: $PrimaryThemeColor ;*/
		background-color:#E7F2F7;
		border-radius:5px;
		outline:none;
	}

}

body{
	/*background-color:rgba(248,248,250,0.5);*/
	font-family: $Overpass ;
	font-weight:400;
	font-size:14px;
	color: $BodyFontColor ;
	line-height:1.43;
	/*letter-spacing:-0.3px;*/

	&::-webkit-scrollbar{
		width:6px;
		height:6px;
	}
	   
	&::-webkit-scrollbar-track{
		background-color:#E7F2F7;
		border-radius:5px;
	}
	   
	&::-webkit-scrollbar-thumb{
		/*background-color: $PrimaryThemeColor ;*/
		background-color: $PrimaryThemeColor ;
		border-radius:5px;
		outline:none;
	}

	&.sidr-open{
		position:fixed;
		top:0;
		right:0;
		bottom:0;
		left:0;
		overflow:hidden !important;
	}

}

/*::-webkit-scrollbar{
	width:0;
}*/

img{
	width:auto;
	max-width:100%;
	height:auto;
	border:0;
}

ul, ol{
	margin:0;
	padding:0;
	list-style:none;
}

ol{
	list-style:decimal inside;
}

h1, h2, h3, h4, h5, h6{
	margin:0;
	padding:0;
	font-family: $Montserrat ;
	font-weight:700;
	font-size:30px;
	color: $SectionTitleColor ;
	line-height:1.25;
}

p{
	margin:0;
	padding:0;
}

.btn{
    white-space:normal;
}

a,
a:hover,
a:visited{
	color: $PrimaryThemeColor ;
	/*word-break:break-all;*/
}

::selection{
	background-color: $PrimaryThemeColor ;
	color:#fff;
}

::-moz-selection{
	background-color: $PrimaryThemeColor ;
	color:#fff;
}

/* overlay */

body > .overlay{
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:999;
	content:" ";
	width:auto;
	height:auto;
	background:rgba(0,0,0,0.6);
	backdrop-filter: blur(3px);
	opacity:0;
	visibility:hidden;
	transition:all 0.3s ease-in-out;
	-webkit-transition:all 0.3s ease-in-out;
}

body.main-menu-open > .overlay,
body.header-search-active > .overlay{
	opacity:1;
	visibility:visible;
}

/* modal */

.modal{
    z-index:99999;
}

.modal-backdrop.show{
    z-index:99998;
    opacity:0.8;
}

/* grecaptcha-badge */

.grecaptcha-badge{
	opacity:0;
	visibility:hidden;
}

/* ========================== */
/* END OF RESET CSS */
/* ========================== */

/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */

/* EXTRA LARGE DESKTOP PART */
@media screen and (min-width:1920px){
}

/* EXTRA LARGE DESKTOP PART */
@media screen and (max-width:1919px){
}

/* LARGE DESKTOP PART */
@media screen and (max-width:1400px){
}

/* DESKTOP PART */
@media screen and (max-width:1199px){
}

/* TABLET LANDSCAPE PART */
@media screen and (max-width:991px){
}

/* TABLET PROTRAIT PART */
@media screen and (max-width:767px){

	body{
		font-size:15px;
	}

}

/* HIGH DEFINITION PHONE PART */
@media screen and (max-width:575px){
}

/* SMART PHONE LANDSCAPE PART */
@media screen and (max-width:479px){
}

/* SMART PHONE LANDSCAPE PART */
@media (max-width:812px) and (orientation:landscape){
}

/* ========================== */
/* END OF RESPONSIVE CSS */
/* ========================== */
