/* container */

.container{
	/*max-width:1240px;*/
	padding-left:20px;
	padding-right:20px;
}

/* pagination */

.pagination{
	display:block;
	text-align:center;

	&:after{
		display:block;
		content:" ";
		clear:both;
	}

	ul.pagination{
		margin:0 auto;
		border-radius:0;
	}

	ul.pagination > a,
	ul.pagination > span{
		display:inline-block;
		vertical-align:top;
		width:auto;
		height:auto;
		margin-right:5px;
		margin-bottom:5px;
		margin-left:5px;
		padding:10px 16px 11px 16px;
		background-color:#fff;
		border:none;
		border-radius:35px;
		box-shadow:0 5px 20px rgba(0,0,0,0.12);
		text-align:center;
		font-weight:500;
		font-size:12px;
		color:#393D41;
		line-height: 1.4;
		transition: all 0.3s ease-in-out;
	}

	ul.pagination > span.current{
		background-color: $PrimaryThemeColor ;
		color:#fff;
	}

	.alignleft,
	.alignright{

		a{
			display:block;
			padding:10px 20px 11px 20px;
			background-color: $PrimaryThemeColor ;
			border:none;
			border-radius:35px;
			box-shadow:0 5px 20px rgba(0,0,0,0.05);
			text-align:center;
			font-weight:500;
			font-size:12px;
			color:#fff;
			line-height:1.4;
			transition:all 0.3s ease-in-out;
		}

	}

	.alignleft{
		float:left;
	}

	.alignright{
		float:right;
	}

}

/* viq-section-loader */

.viq-section-loader{
    position:relative;
    width:70px;
    height:70px;

	&:before,
	&:after{
		content:" ";
		position:absolute;
		background:center center no-repeat;
		background-size:100% 100%;
		animation:VIQSectionLoaderAnimation 1s linear infinite;
	}

	&:before{
		top:0;
		right:0;
		bottom:0;
		left:0;
		//background-image:url(./assets/images/loader/Section-Loader-Dark.svg);
		background-image:url(../images/loader/Section-Loader-Dark.svg);
	}

	&:after{
		top:15px;
		right:15px;
		bottom:15px;
		left:15px;
		background-image:url(../images/loader/Section-Loader-Light.svg);
		animation-direction:reverse;
	}

}

@keyframes VIQSectionLoaderAnimation{
    0%{
        transform:rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}

/* COMMON PAGE */

.overflow-visible{
	overflow:visible !important;
	z-index:1;
}

.font-weight-thin{
	font-weight:100;
}

.font-weight-extralight{
	font-weight:200;
}

.font-weight-light{
	font-weight:300;
}

.font-weight-regular{
	font-weight:400;
}

.font-weight-medium{
	font-weight:500;
}

.font-weight-semibold{
	font-weight:600;
}

.font-weight-bold{
	font-weight:700;
}

.font-weight-extrabold{
	font-weight:800;
}

.font-weight-black{
	font-weight:900;
}

.font-style-normal{
	font-style:normal;
}

.font-style-italic{
	font-style:italic;
}

.text-transform-uppercase{
	text-transform:uppercase;
}

.text-transform-none{
	text-transform:none;
}

@for $letterSpacing from -9 through 9{

	.letter-spacing-#{$letterSpacing} {
		letter-spacing: #{$letterSpacing}px ;
	}

}

@for $zIndex from -9 through 9{

	.z-index-#{$zIndex} {
		z-index: #{$zIndex} ;
	}

}

/* section-heading */

.section-heading{

	img{
		margin-bottom:30px;
	}

	.tagline{
		margin-bottom:7px;
		font-family: $Overpass ;
		font-weight:500;
		font-size:15px;
		color: $SectionTaglineColor ;
		line-height:1.25;
	}

	.title{
		margin-bottom:10px;
		/*text-transform:uppercase;*/
		font-family: $Montserrat ;
		font-weight:700;
		font-size:30px;
		color: $SectionTitleColor ;
		line-height:1.2;
		/*letter-spacing:-0.5px;*/
	}

	.subtitle{
		margin-bottom:23px;
		font-family: $Overpass ;
		font-weight:400;
		font-size:15px;
		color: $SectionSubtitleColor ;
		line-height:1.43;
	}

}

/* mat-button */

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab{
	padding:0 20px !important;
	box-shadow:initial !important;
	font-family: $Montserrat !important;
	font-weight:600 !important;
	font-size:14px !important;
	line-height:45px !important;
}

/* mat-button */

.mat-button{

	&.mat-primary{
		color: $PrimaryThemeColor ;
	}

}

/* mat-stroked-button */

.mat-stroked-button{

	&:not([disabled]){
		border-color: $PrimaryThemeColor ;
	}

	&.mat-primary{
		color: $PrimaryThemeColor ;
	}

}

/* mat-flat-button */

.mat-flat-button{

	&.mat-primary{
		background-color: $PrimaryThemeColor ;

		&:disabled{
			background-color: $PrimaryThemeColor ;
			color:#fff;
			opacity:0.75;
		}

	}

}

/* mat-raised-button */

.mat-raised-button{
	
	&.mat-primary{
		background-color: $PrimaryThemeColor ;
	}

}

/* mat-fab */

.mat-fab,
.mat-mini-fab{
	
	&.mat-primary{
		background-color: $PrimaryThemeColor ;
	}
	
}

/* tippy-box */

div[data-tippy-root]{
	z-index:9999 !important;
}

.tippy-box{
	padding:7px 14px 6px 14px;
	background-color:#003E52;
	text-align:left;
	font-weight:300;
	font-size:12px;
	color:#fff;

	.tippy-content{
		padding:0;
		word-break:break-word;

		strong{
			font-weight:700;
			font-size:14px;
		}

		.tippy-html{
			padding:11px 6px 11px 6px;

			> *:last-child{
				margin-bottom:0 !important;
			}

			p{
				margin-bottom:10px;
			}

		}

	}

	.tippy-arrow{
		color:#003E52;
	}
	
}

/* mat-snack-bar-container */

.mat-snack-bar-container{

	> .mat-simple-snackbar{
		/*font-family:inherit;*/
		font-weight:300;
		font-size:15px;
		line-height:1.5;

		> span{
			position:relative;
			margin-left:0;
			padding-left:38px;
		}

		> .mat-simple-snackbar-action{
		    color:#fff;
		}

	}

	/* snackbar / snackbar-withclose */

	&.success-snackbar > .mat-simple-snackbar > span:before,
	&.failed-snackbar > .mat-simple-snackbar > span:before,
	&.info-snackbar > .mat-simple-snackbar > span:before,
	&.success-snackbar-withclose > .mat-simple-snackbar > span:before,
	&.failed-snackbar-withclose > .mat-simple-snackbar > span:before,
	&.info-snackbar-withclose > .mat-simple-snackbar > span:before{
		position:absolute;
		top:calc(50% - 17px);
    	left:4px;
		font-family:"viq-icons";
    	font-style:normal !important;
		font-weight:normal;
		font-size:24px;
	}
	
	&.success-snackbar,
	&.success-snackbar-withclose{
		max-width:600px;
		background-color: $AlertGreenColor ;
		box-shadow:0 3px 10px rgba(0,0,0,0.16);
		color:#fff;

		> .mat-simple-snackbar > span:before{
			content:"\e805";
			/*color: $PrimaryThemeColor ;*/
		}

	}

	&.failed-snackbar,
	&.failed-snackbar-withclose{
		max-width:600px;
		background-color: $AlertRedColor ;
		box-shadow:0 3px 10px rgba(0,0,0,0.16);
		color:#fff;

		> .mat-simple-snackbar > span:before{
			content:"\e803";
			/*color:#f44336;*/
		}

	}

	&.info-snackbar,
	&.info-snackbar-withclose{
		max-width:400px;
		background-color: $PrimaryThemeColor ;
		box-shadow:0 3px 10px rgba(0,0,0,0.16);
		color:#fff;

		> .mat-simple-snackbar > span:before{
			content:"\e801";
			/*color:#f44336;*/
		}

	}

	&.success-snackbar-withclose,
	&.failed-snackbar-withclose,
	&.info-snackbar-withclose{

		.mat-button{
			display:flex;
			align-items:center;
			justify-content:center;
			padding:0 10px;
			border-radius:35px;

			.mat-button-wrapper{
				margin-top:4px;
				font-size:0;
			
				&:before{
					content:"\e800";
					font-family:"viq-icons";
					font-style:normal !important;
					font-weight:normal;
					font-size:16px;
					opacity:0.5;
				}
	
			}

		}

	}

	/* cookie-snackbar */

	&.cookie-snackbar{
		max-width:600px;
		background-color:#003e52;
		border-radius:8px;
		box-shadow:0 6px 20px rgba(0,0,0,0.13);
		padding:18px 25px;

		> .mat-simple-snackbar{
			font-weight:400;
			font-size:14px;
			color: #fff;
	
			> span{
				padding-left:0;
			}

		}

		.mat-simple-snackbar-action{
			margin:0 0 0 15px;

			.mat-button{
				background-color:#fff;
				font-weight:700 !important;
				font-size:12px !important;
				color:#003e52;
				line-height:33px !important;
			}
			
		}

	}

}

/* mat-menu-panel */

.mat-menu-panel{
	min-width:150px !important;
	border-radius:8px !important;
	box-shadow:0 6px 20px rgba(0,0,0,0.13);

	.mat-menu-content:not(:empty){
		padding-top:11px;
		padding-bottom:11px;
	}

	.mat-menu-item{
		height:auto;
		padding:11px 20px 11px 20px;
		font-family: $Montserrat ;
		font-weight:600;
		font-size:12px;
		color:#414244;
		line-height:1.17;

		i{
			display:inline-block;
			vertical-align:top;
			width:10px;
			margin-top:-1px;
			margin-right:11px;
			text-align:center;
			font-weight:400;
			font-size:110%;
			color:#414244;
		}

		img{
			width:13px;
			margin-right:8px;
			// filter:brightness(0) invert(0);
			-webkit-transition:all 0.3s ease-in-out;
			transition:all 0.3s ease-in-out;
		}

		.sticker{
			width:20px;
			min-width:20px;
			height:20px;
			border-radius:50%;
			margin-top:-2px;
			margin-bottom:0;

			figcaption{
				display:inline-flex;
				align-items:center;
				justify-content:center;
				width:100%;
				height:100%;
				border-radius:inherit;
				background-color:#003e52;
				text-transform:uppercase;
				font-family: $Montserrat ;
				font-weight:400;
				font-size:9px;
				color:#fff;
				line-height:1;
			}

			img{
				width:100%;
				height:100%;
				margin-right:0;
				border-radius:inherit;
				object-fit:cover;
				object-position:center center;
				filter:initial;
			}

		}

		&.current-menu-item{
			color: $PrimaryThemeColor ;

			img{
				filter:brightness(1) invert(0);
			}

		}

	}

}

/* mat-select-panel-wrap */

.mat-select-panel-wrap{
	
	.cardtype-icon{
		max-height:26px;
    	margin-right:7px;
	}

}

/* members-list */

.members-list{
	padding-top:5px;
    padding-bottom:5px;
	border-radius:5px;
	box-shadow:0 5px 15px rgba(0,0,0,0.13) !important;

	/* members-list-item */

	.members-list-item{
		height:auto;
		padding:10px 15px 10px 15px;
		font-family: $Overpass ;
		color:#414244;
		line-height:initial;

		.icon{
			width:40px;
			height:40px;
			background-color:#f1f7f9;
			border-radius:50%;
			font-size:17px;
			line-height:1;
		}

		.data{

			> *:last-child{
				margin-bottom:0 !important;
			}

			.title{
				margin-bottom:4px;
				font-weight:700;
				font-size:14px;
			}

			.email{
				margin-bottom:10px;
				font-weight:400;
				font-size:12px;
			}

		}

	}

}

/* body-bg-color */

.mat-drawer-container{
    background-color:#f6fbfd;
}

/* body-bg-color */

.responsive-version{
	display:none !important;
}

/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */

/* EXTRA LARGE DESKTOP PART */
@media screen and (min-width:1920px){

	/* container */

    .container{
        /*max-width:1240px;*/
    }
	
}

/* EXTRA LARGE DESKTOP PART */
@media screen and (max-width:1919px){

    /* container */

    .container{
        /*max-width:1170px;*/
    }

}

/* LARGE DESKTOP PART */
@media screen and (max-width:1400px){

    /* container */

    .container{
       /*max-width:1170px;*/
    }

}

/* DESKTOP PART */
@media screen and (max-width:1199px){
}

/* TABLET LANDSCAPE PART */
@media screen and (max-width:991px){
}

/* TABLET PROTRAIT PART */
@media screen and (max-width:767px){
	.responsive-version{
		display: block !important;
	}
	.responsive-version.d-flex{
		display: flex !important;
	}
	.desktop-version{
		display: none !important;
	}
}

/* HIGH DEFINITION PHONE PART */
@media screen and (max-width:575px){
}

/* SMART PHONE LANDSCAPE PART */
@media screen and (max-width:479px){
}

/* SMART PHONE LANDSCAPE PART */
@media (max-width:812px) and (orientation:landscape){
}

/* ========================== */
/* END OF RESPONSIVE CSS */
/* ========================== */
