.jqx-widget-content-energyblue{border-color: #a4bed4;}
.jqx-fill-state-normal-energyblue, .jqx-widget-header-energyblue{border-color:#a4bed4; background-color:#e0e9f5}
.jqx-fill-state-hover-energyblue{border-color:#a4bed4;  background-color:#cfdde9}
.jqx-fill-state-pressed-energyblue{border-color:#5e8cb3; background-color:#cfdde9}
.jqx-fill-state-focus-energyblue { border-color: #477396; }
.jqx-scrollbar-state-normal-energyblue, .jqx-grid-bottomright-energyblue, .jqx-panel-bottomright-energyblue, .jqx-listbox-bottomright-energyblue{background-color:#e0e9f5}
.jqx-widget-energyblue .jqx-grid-column-header-energyblue, .jqx-grid-cell-energyblue, .jqx-widget-energyblue .jqx-grid-cell-energyblue, .jqx-widget-energyblue .jqx-grid-group-cell-energyblue, .jqx-grid-group-cell-energyblue{border-color:#a4bed4}
.jqx-tabs-title-selected-bottom-energyblue, .jqx-tabs-selection-tracker-bottom-energyblue, .jqx-tabs-title-selected-top-energyblue, .jqx-tabs-selection-tracker-top-energyblue{border-color:#a4bed4; border-bottom:1px solid #fff; background:#fff}
.jqx-grid-cell-sort-alt-energyblue, .jqx-grid-cell-filter-alt-energyblue, .jqx-grid-cell-pinned-energyblue, .jqx-grid-cell-alt-energyblue, .jqx-grid-cell-sort-energyblue{ background-color:#e7eef8}
.jqx-grid-cell-selected-energyblue{ background-color:#cfdde9 !important;  color:#2b465e !important;}
.jqx-grid-cell-hover-energyblue{ background-color:#cfdde9 !important;}
.jqx-menu-vertical-energyblue{background: #e0e9f5;}
.jqx-layout-energyblue
{
    background-color: #a4bed4;
}