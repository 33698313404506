/* ========================== */
/* START OF CUSTOM FONTS CSS */
/* ========================== */

/* viq-icons */

@font-face{
    font-family:'viq-icons';
    //src:url('assets/fonts/viq-icons.eot?53067201');
    src:url('../fonts/viq-icons.eot?53067201');
    src:url('../fonts/viq-icons.eot?53067201#iefix') format('embedded-opentype'),
        url('../fonts/viq-icons.woff2?53067201') format('woff2'),
        url('../fonts/viq-icons.woff?53067201') format('woff'),
        url('../fonts/viq-icons.ttf?53067201') format('truetype'),
        url('../fonts/viq-icons.svg?53067201#viq-icons') format('svg');
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}
 
[class^="viq-"]:before, [class*=" viq-"]:before {
    font-family:"viq-icons";
    font-style:normal;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

.viq-close-line:before { content: '\e800'; } /* '' */
.viq-info-fill:before { content: '\e801'; } /* '' */
.viq-eye-open:before { content: '\e802'; } /* '' */
.viq-info-line:before { content: '\e803'; } /* '' */
.viq-add-user-line:before { content: '\e804'; } /* '' */
.viq-check-line:before { content: '\e805'; } /* '' */
.viq-eye-close:before { content: '\e806'; } /* '' */
.viq-home-line:before { content: '\e807'; } /* '' */
.viq-settings-line:before { content: '\e808'; } /* '' */
.viq-upload-line:before { content: '\e809'; } /* '' */
.viq-bell-line:before { content: '\e80a'; } /* '' */
.viq-carret-right:before { content: '\e80b'; } /* '' */
.viq-search-line:before { content: '\e80c'; } /* '' */
.viq-share-line:before { content: '\e80d'; } /* '' */
.viq-plus-fill:before { content: '\e80e'; } /* '' */
.viq-hamburger-line:before { content: '\e80f'; } /* '' */
.viq-share-line-2:before { content: '\e810'; } /* '' */
.viq-export-line:before { content: '\e811'; } /* '' */
.viq-trash-line:before { content: '\e812'; } /* '' */
.viq-download-line:before { content: '\e813'; } /* '' */
.viq-filter-line:before { content: '\e814'; } /* '' */
.viq-play-circle-line:before { content: '\e815'; } /* '' */
.viq-rename-line:before { content: '\e816'; } /* '' */
.viq-copy-line:before { content: '\e817'; } /* '' */
.viq-edit-line:before { content: '\e818'; } /* '' */
.viq-three-dot-menu:before { content: '\e819'; } /* '' */
.viq-dictionary-line:before { content: '\e81a'; } /* '' */
.viq-lable-line:before { content: '\e81b'; } /* '' */
.viq-media-player-line:before { content: '\e81c'; } /* '' */
.viq-plus-line:before { content: '\e81d'; } /* '' */
.viq-check-simple-line:before { content: '\e81e'; } /* '' */
.viq-add-folder-line:before { content: '\e81f'; } /* '' */
.viq-upload-fill:before { content: '\e820'; } /* '' */
.viq-sort-line:before { content: '\e821'; } /* '' */
.viq-teammate-line:before { content: '\e822'; } /* '' */
.viq-user-check:before { content: '\e823'; } /* '' */
.viq-reload-line:before { content: '\e824'; } /* '' */
.viq-user-line:before { content: '\e825'; } /* '' */
.viq-add-group-line:before { content: '\e826'; } /* '' */
.viq-cog-line:before { content: '\e827'; } /* '' */
.viq-delete-group-line:before { content: '\e828'; } /* '' */
.viq-envelop-open-line:before { content: '\e829'; } /* '' */
.viq-invite-accepted-line:before { content: '\e82a'; } /* '' */
.viq-list-line:before { content: '\e82b'; } /* '' */
.viq-template-edit-line:before { content: '\e82c'; } /* '' */
.viq-template-line:before { content: '\e82d'; } /* '' */
.viq-user-active-line:before { content: '\e82e'; } /* '' */
.viq-user-deactive-line:before { content: '\e82f'; } /* '' */
.viq-bold-line:before { content: '\e830'; } /* '' */
.viq-clock-line:before { content: '\e831'; } /* '' */
.viq-copy-line2:before { content: '\e832'; } /* '' */
.viq-down-arrow-line:before { content: '\e833'; } /* '' */
.viq-highlighter-line:before { content: '\e834'; } /* '' */
.viq-lock-line:before { content: '\e835'; } /* '' */
.viq-pause-circle-line:before { content: '\e836'; } /* '' */
.viq-play-circle-line-2:before { content: '\e837'; } /* '' */
.viq-play-fill:before { content: '\e838'; } /* '' */
.viq-strikethrough-line:before { content: '\e839'; } /* '' */
.viq-type-line:before { content: '\e83a'; } /* '' */
.viq-underline-line:before { content: '\e83b'; } /* '' */
.viq-refresh-line:before { content: '\e83c'; } /* '' */
.viq-italic-line:before { content: '\e83d'; } /* '' */
.viq-copy-line2-1:before { content: '\e83e'; } /* '' */
.viq-link-line:before { content: '\e83f'; } /* '' */
.viq-video-camera:before { content: '\e840'; } /* '' */
.viq-video-fullscreen:before { content: '\e841'; } /* '' */
.viq-pause-fill:before { content: '\e842'; } /* '' */
.viq-play-circle-fill:before { content: '\e843'; } /* '' */
.viq-bookmark-line:before { content: '\e844'; } /* '' */
.viq-cut-line:before { content: '\e845'; } /* '' */
.viq-memo-line:before { content: '\e846'; } /* '' */
.viq-file-open-line:before { content: '\e847'; } /* '' */
.viq-credit-card-line:before { content: '\e848'; } /* '' */
.viq-file-fill:before { content: '\e849'; } /* '' */
.viq-transcription-complete-line:before { content: '\e84a'; } /* '' */
.viq-payment-failed:before { content: '\e84b'; } /* '' */
.viq-payment-successful:before { content: '\e84c'; } /* '' */
.viq-share-line-3:before { content: '\e84d'; } /* '' */

/* ========================== */
/* END OF CUSTOM FONTS CSS */
/* ========================== */
