/* default-text */

.default-text{

	/* headings */

	h1{
	    margin-bottom:15px;
	    /*text-transform:uppercase;*/
	    font-weight:900;
	    font-size:25px;
	}

	h2{
	    margin-bottom:20px;
	    /*text-transform:uppercase;*/
	    font-weight:700;
	    font-size:22px;
	}

	h3{
	    margin-bottom:13px;
	    /*text-transform:uppercase;*/
	    font-weight:700;
	    font-size:24px;
	}

	h4{
	    margin-bottom:20px;
	    /*text-transform:uppercase;*/
		font-weight:400;
		font-size:18px;
		line-height:1.67;
	}
	
	h5{
	    margin-bottom:13px;
	    /*text-transform:uppercase;*/
		font-weight:600;
		font-size:18px;
		line-height:1.67;
	}

	/* hr */

	hr{
	    margin-bottom:25px;
	}

	/* paragraph */

	p{
		margin-bottom:25px;

		a{
			color: $SecondaryThemeColor ;
			-webkit-transition:all 0.3s ease-in-out;
			transition:all 0.3s ease-in-out;

			&:hover{
				color: $PrimaryThemeColor ;
			}

		}

	}

	/* ul:not(.check-list):not(.woocommerce-error) */

	ul:not(.check-list):not(.woocommerce-error){
		margin-bottom:30px;
		
		li{
			position:relative;
			margin-bottom:10px;
			padding-left:22px;
			
			&:before{
				position:absolute;
				top:12px;
				left:0;
				content:" ";
				width:7px;
				height:7px;
				background-color:#000;
				border-radius:50%;
			}

			a{
				color: $SecondaryThemeColor ;
				-webkit-transition:all 0.3s ease-in-out;
				transition:all 0.3s ease-in-out;

				&:hover{
					color: $PrimaryThemeColor ;
				}

			}

			ul{
			    margin-top:20px;
			}

		}
		
	}

	/* ol */

	ol{
		display:inline-block;
		vertical-align:top;
		width:100%;
		max-width:900px;
		margin-bottom:18px;
		padding-left:50px;
		list-style:none;
		counter-reset:orderedListCounter;
		
		li{
			position:relative;
			margin-bottom:20px;
			counter-increment:orderedListCounter;

			&:before{
				content:counter(orderedListCounter) ". ";
				position:absolute;
				left:-25px;
				font-weight:700;
			}

			a{
				color: $SecondaryThemeColor ;
				-webkit-transition:all 0.3s ease-in-out;
				transition:all 0.3s ease-in-out;

				&:hover{
					color: $PrimaryThemeColor ;
				}

			}

			strong{
				font-weight:700;
			}

			ol{
			    margin-top:25px;
			}

		}
		
	}

	/* dl */

	dl{

		dt{
			margin-bottom:4px;
			font-weight:600;
			color: $SectionTitleColor ;
		}

		dd{
			margin-bottom:20px;
			font-size:14px;
		}

	}

	/* blockquote */

	blockquote{
		margin-top:30px;
		margin-bottom:30px;
		font-size:18px;
		color:#666;
		line-height:1.67;

		> *:last-child{
			margin-bottom:0 !important;
		}

		cite{
			display:block;
			margin-top:25px;
			font-size:13px;
			font-style:normal;

			strong{
				display:block;
				font-weight:700;
				font-size:18px;
				color:#000;
			}

		}

	}

	/* card */

	.card{
		position:relative;
		margin-top:30px;
		margin-bottom:30px;
		padding:25px 30px 25px 30px;
		background-color:#fff;
		border:none;
		border-radius:0;
		box-shadow:0 5px 20px rgba(0,0,0,0.09);

		> *:last-child{
			margin-bottom:0 !important;
		}
		
	}

}

/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */

/* EXTRA LARGE DESKTOP PART */
@media screen and (min-width:1920px){
}

/* EXTRA LARGE DESKTOP PART */
@media screen and (max-width:1919px){
}

/* LARGE DESKTOP PART */
@media screen and (max-width:1400px){
}

/* DESKTOP PART */
@media screen and (max-width:1199px){
}

/* TABLET LANDSCAPE PART */
@media screen and (max-width:991px){
}

/* TABLET PROTRAIT PART */
@media screen and (max-width:767px){
}

/* HIGH DEFINITION PHONE PART */
@media screen and (max-width:575px){
}

/* SMART PHONE LANDSCAPE PART */
@media screen and (max-width:479px){
}

/* SMART PHONE LANDSCAPE PART */
@media (max-width:812px) and (orientation:landscape){
}

/* ========================== */
/* END OF RESPONSIVE CSS */
/* ========================== */
