/*
Theme Name: VIQ
Author: VIQ
Author URI: https://www.unifiedinfotech.net/
Description: VIQ is a responsive WordPress theme exclusively created by VIQ. This theme is based on Bootstrap 4.6.0 CSS framework and using Swiper JS third party javascript plugins. This website is powered by SCSS.
Version: 1.0.0
License: VIQ's License

This theme is licensed under VIQ's License.
Do not use it without valid permission.
*/
/* ========================== */
/* START OF MAIN CSS */
/* ========================== */
@import '../myviq/assets/scss/fonts';
@import '../myviq/assets/scss/fonts';
@import '../myviq/assets/scss/variables';
@import '../myviq/assets/scss/reset';
@import '../myviq/assets/scss/common';

@import '../myviq/assets/scss/default-text';
@import '../myviq/assets/scss/contact-form';
@import '../myviq/assets/scss/modal';

@import '../myviq/assets/scss/login';
@import '../myviq/assets/scss/my-files';
@import '../myviq/assets/scss/profile';

// @import '~video.js/dist/video-js.css';
// @import '~videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
/* ========================== */
/* END OF MAIN CSS */
/* ========================== */
