/* ========================== */
/* START OF SCSS VARIABLES */
/* ========================== */

/* FONTS */
$Montserrat: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;
$Overpass: 'Overpass', Verdana, Geneva, Tahoma, sans-serif;

/* COLORS */
$PrimaryThemeColor: #187EB1;
$SecondaryThemeColor: #1A50B1;

$BodyFontColor: #4f5760;
$HyperlinkFontColor: #3366CC;
$SectionTaglineColor: #101820;
$SectionTitleColor: #101820;
$SectionSubtitleColor: #4f5760;

$ThemeYellowColor: #fccd57;
$ThemePinkColor: pink;
$ThemeRedColor: #e10b0b;

$AlertRedColor: #ed1846;
$AlertGreenColor: #9bc53d;

/* MAT-CORE */
@import '@angular/material/theming';
$custom-typography: mat-typography-config(
  $font-family: $Overpass
);
@include mat-core($custom-typography);
// for video js
// @import '~video.js/dist/video-js.css';
/* ========================== */
/* END OF SCSS VARIABLES */
/* ========================== */
