/* contact-form */

.contact-form {

    /* row */

    .row {
        margin-right: -8px;
        margin-left: -8px;

        >[class*='col-'] {
            padding-right: 8px;
            padding-left: 8px;
        }

    }

    /* form-row */

    .form-row {
        position: relative;
        /*margin-bottom:5px;*/

        /* mat-form-field */

        .mat-form-field {
            width: 100%;

            /* mat-form-field-wrapper */

            .mat-form-field-wrapper {
                margin: 0;
                padding-bottom:2px;
                padding-top:10px;
            }

            .mat-form-field-outline {
                color: #b6b6b6;
            }

            &.mat-form-field-invalid .mat-form-field-outline-thick {
                color: $ThemeRedColor;
            }

            &.mat-focused {

                .mat-form-field-outline {
                    color: $PrimaryThemeColor;
                }

                .mat-form-field-label {
                    color: $PrimaryThemeColor;
                }

            }

            /* mat-form-field-flex */

            .mat-form-field-flex {
                padding: 0 10px 0 0px;
                /* horizontal gap */
            }

            /* mat-form-field-infix */

            .mat-form-field-infix {
                padding: 11px 0 18px 0;
                /* vertical gap */

                input.mat-input-element,
                .mat-select-value {
                    height: 15px;
                    padding-top: 2px;
                    color: #71777e;
                    line-height: 1;


                    &.mat-chip-input-ignore {
                        width: 100%;
                    }

                }

                textarea.mat-input-element {
                    height: 258px;
                    resize: none;
                }

                /* mat-select-arrow-wrapper */

                .mat-select-arrow-wrapper {

                    &:before {
                        position: relative;
                        top: 2px;
                        content: "\e833";
                        font-family: "viq-icons";
                        font-size: 8px;
                        color: #b6b6b6;
                    }

                    .mat-select-arrow {
                        display: none;
                    }

                }

            }

            /* mat-form-field-suffix */

            .mat-form-field-suffix {

                /* mat-datepicker-toggle */

                .mat-datepicker-toggle {

                    .mat-icon-button {
                        top: 2px;
                        right: -5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 !important;
                    }

                }

            }

            /* mat-form-field-subscript-wrapper */

            .mat-form-field-subscript-wrapper {
                position: relative;
            }

            &.mat-form-field-disabled {

                /* mat-form-field-flex */

                .mat-form-field-flex {
                    opacity: 0.75;
                }

                /* mat-form-field-infix */

                .mat-form-field-infix {

                    /* mat-select-arrow-wrapper */

                    .mat-select-arrow-wrapper:before {
                        opacity: 0;
                    }

                }

            }

            /* action-icon */

            .action-icon {
                position: relative;
                top: -3px;
                padding-left: 10px;
                font-size: 15px;
                color: #b6b6b6;
                line-height: 1;
                cursor: pointer;

                >mat-icon {
                    display: inline-block;
                    padding-bottom: 15px;
                }

                >span {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;

                    &:last-child,
                    &:only-child {
                        margin-right: 0;
                    }

                    &.success {
                        color: $AlertGreenColor;
                    }

                    &.failure {
                        color: $AlertRedColor;
                    }

                }

                i {
                    -webkit-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;

                    &.active {
                        color: $PrimaryThemeColor;
                    }

                }

            }

            /* phonenumber-with-country */

            &.phonenumber-with-country {

                .mat-form-field-flex {
                    padding-left: 85px;
                }

                .iti__selected-flag {
                    left: -72px;
                    padding: 0;
                    background-color: transparent;

                    .iti__selected-dial-code {
                        margin-top: 2px;
                    }

                }

                .iti__country-list {
                    left: -40px;
                    top: 18px;
                    max-width: 250px;

                    .iti__country {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                }

                .iti--allow-dropdown input,
                .iti--allow-dropdown input[type=text],
                .iti--allow-dropdown input[type=tel],
                .iti--separate-dial-code input,
                .iti--separate-dial-code input[type=text],
                .iti--separate-dial-code input[type=tel] {
                    padding-left: 0 !important;
                }

            }

        }

        /* mat-checkbox */

        .mat-checkbox {

            /* mat-checkbox-layout */

            .mat-checkbox-layout {
                white-space: normal;
            }

            /* mat-checkbox-inner-container */

            .mat-checkbox-inner-container {
                width: 20px;
                height: 20px;
                margin-top: -1px;

                .mat-ripple-element {
                    background-color: $PrimaryThemeColor;
                }

                .mat-checkbox-frame {
                    border-width: 1px;
                    border-radius: 3px;
                    border-color: #b6b6b6;
                }

                .mat-checkbox-background {

                    .mat-checkbox-checkmark {
                        -webkit-transform: scale(0.75);
                        transform: scale(0.75);
                    }

                }

            }

            /* mat-checkbox-label */

            .mat-checkbox-label {
                font-family: $Overpass;
                font-size: 14px;
                color: #373737;
                line-height: inherit;
            }

            &.mat-checkbox-checked {

                /* mat-checkbox-inner-container */

                .mat-checkbox-inner-container {

                    .mat-checkbox-background {
                        background-color: $PrimaryThemeColor;
                    }

                }

            }

            ~.mat-error {
                margin-top: -7px;
                margin-bottom: 7px;
            }

        }

        /* mat-button */

        .mat-flat-button.mat-primary,
        .mat-raised-button.mat-primary {
            min-width: 145px;
            /*padding:0 20px;
			background-color: $PrimaryThemeColor ;
			border-radius:5px;
			font-family: $Montserrat ;*/

            &.login-button {
                min-height: 50px;
                font-weight: 700 !important;
                font-size: 16px !important;
            }

        }

        /* mat-chip-list */

        .mat-chip-list {

            .mat-chip.mat-standard-chip {
                display: inline-block;
                vertical-align: top;
                min-height: 26px;
                padding: 0 25px 0 10px;
                background-color: #fff;
                border: 1px solid #aab6ba;
                font-size: 12px;
                color: #003e52;
                line-height: 26px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                /*.mat-chip-remove.mat-icon{
					width:auto;
					height:auto;
					opacity:1;
					font-size:13px;
				}*/

                .mat-chip-remove {
                    width: auto;
                    height: auto;
                    position: absolute;
                    top: -1px;
                    right: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    font-size: 9px;
                    opacity: 1;
                }

            }

        }

        /* login-trouble */

        .login-trouble {
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 12px;
            color: #6d7377;

            a {
                font-weight: 700;
                color: $PrimaryThemeColor;
            }

        }

        /* login-help */

        .login-help {
            font-weight: 700;
            font-size: 14px;
            color: #757575;

            a {
                font-weight: inherit;
                color: $PrimaryThemeColor;
                cursor: pointer;
            }
            a:not([href]):not([tabindex]) {
                color: $PrimaryThemeColor;
                cursor: pointer;
            }
        }

        /* otp-zone */

        &.otp-zone {

            .wrapper {
                display: flex;

                .otp-input {
                    flex: 1 1 auto;
                    width: 70px;
                    height: 90px;
                    margin-right: 10px;
                    margin-left: 10px !important;
                    padding-top: 10px !important;
                    background-color: #fff;
                    border-color: #b6b6b6;
                    border-radius: 8px;
                    font-size: 50px;
                    font-weight: 700;
                    color: #71777e;
                    line-height: 1;

                    &:focus {
                        border-color: $PrimaryThemeColor;
                    }

                }

            }

        }

        /* otp-timer */

        .otp-timer {
            font-weight: 700;
            font-size: 18px;
            color: $AlertGreenColor;
        }

        /* filelocation-label */

        .filelocation-label {
            margin-bottom: 12px;
            font-family: $Montserrat;
            font-weight: 700;
            font-size: 14px;
            color: $SectionTitleColor;
        }

        /* filelocation-selector */

        .filelocation-selector {
            padding: 5px 15px;
            border: 1px solid #b6b6b6;
            border-radius: 6px;

            /* current-location */

            .current-location {

                .file-icon {
                    margin-right: 10px;
                }

                .file-location {

                    p {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1;
                        letter-spacing: normal;
                        color: #222528;
                    }

                }

            }

            /* change-location */

            .change-location {

                button {
                    color: $PrimaryThemeColor;

                    .mat-button-wrapper {
                        padding: 0;
                        font-weight: 700;
                        font-size: 13px;
                        color: $PrimaryThemeColor;
                    }

                    .mat-button-focus-overlay {
                        display: none;
                    }

                }

            }

        }

    }

}

/* viq-dropzone */

.viq-dropzone {
    height: auto;
    min-height: 360px;
    /*max-height:300px;*/
    border: 4px dashed #dfdfdf;
    font-size: 14px;
    color: #414244;

    /* ngx-dropzone-label */

    ngx-dropzone-label {
        margin: 0 auto;
        padding: 30px 20px 30px 20px;

        /* notice-icon */

        .notice-icon {
            margin-bottom: 25px;

            i {
                font-size: 65px;
                color: $PrimaryThemeColor;
                line-height: 1;
            }

        }

        /* notice-data */

        .notice-data {
            max-width: 340px;

            >*:last-child {
                margin-bottom: 0 !important;
            }

            p {
                margin-bottom: 12px;

                strong {
                    // font-family: $Montserrat ;
                    font-weight: 700;
                    color: #000;
                }

            }

        }

    }

}

/* viq-imagecropper */

.viq-imagecropper {
    border: 4px dashed #dfdfdf;

    /* cropper */

    .cropper {
        outline: rgba(255, 255, 255, 0.5) solid 100vw !important;

        &:after {
            border: 5px solid #fff !important;
            opacity: 1 !important;
            box-shadow: 0 0 10px rgb(0, 0, 0, 15%);
        }

        /* resize */

        .resize {
            padding: 6px !important;
            opacity: 1 !important;

            .square {
                width: 16px !important;
                height: 16px !important;
                background: #fff !important;
                border: none !important;
                border-radius: 50%;
                box-shadow: 0 0 10px rgb(0, 0, 0, 15%);
            }

        }


    }

    /* mat-mini-fab */

    .mat-mini-fab {
        top: calc(100% + 10px);
        right: -6px;
        width: 30px;
        height: 30px;
        padding: 0 !important;
        font-size: 11px !important;
    }

}

/* mat-select-panel-wrap */

.mat-select-panel-wrap {

    .mat-option.mat-selected:not(.mat-option-disabled) {
        color: $PrimaryThemeColor;
    }

}

/* file-tree-backdrop */

.file-tree-backdrop {
    /*-webkit-backdrop-filter:blur(2px);
	backdrop-filter:blur(2px);
	background-color:rgba(16,24,32,0.75);*/

    ~.cdk-overlay-connected-position-bounding-box {

        /* mat-menu-panel */

        .mat-menu-panel {
            position: relative;
            right: -16px;
            max-width: initial;
            /*padding:5px 15px;*/
            border: 1px solid $PrimaryThemeColor;
            border-radius: 5px !important;

            .mat-menu-content {
                padding: 10px;
            }

        }

    }

}

/* mat-tree */

.mat-tree {
    /*display:none;*/
    padding: 8px 20px 8px 8px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    .mat-nested-tree-node {

        div[role=group] {
            padding-left: 15px;

            >.mat-tree-node {
                /*padding-left:35px;*/
            }

        }

        .mat-tree-node {
            position: relative;
            display: block;
            min-height: 40px;
            padding-left: 45px;

            &:empty {
                display: none;
            }

            &.option-selected {
                background-color: #fff;
                border-radius: 5px;
                box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
            }

        }

        .mat-icon-button {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 19px !important;
            line-height: 1 !important;
        }

        .file-tree-label {
            display: flex;
            align-items: center;
            min-height: 40px;
            cursor: pointer;

            &.option-selected {
                background-color: #fff;
                border-radius: 5px;
                box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
            }

            img {
                margin-right: 7px;
            }

        }

        .example-tree-invisible {
            display: none;
        }

    }

}

/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */

/* EXTRA LARGE DESKTOP PART */
@media screen and (min-width:1920px) {}

/* EXTRA LARGE DESKTOP PART */
@media screen and (max-width:1919px) {}

/* LARGE DESKTOP PART */
@media screen and (max-width:1400px) {}

/* DESKTOP PART */
@media screen and (max-width:1199px) {}

/* TABLET LANDSCAPE PART */
@media screen and (max-width:991px) {}

/* TABLET PROTRAIT PART */
@media screen and (max-width:767px) {

    /* contact-form */

    .contact-form {

        /* form-row */

        .form-row {

            /* otp-zone */

            &.otp-zone {

                .wrapper {

                    .otp-input {
                        width: 45px;
                        height: 55px;
                        margin-right: 5px !important;
                        margin-left: 5px !important;
                        padding-top: 9px;
                        border-radius: 5px;
                        font-size: 40px;
                    }

                }

            }

        }

    }

}

/* HIGH DEFINITION PHONE PART */
@media screen and (max-width:575px) {

    /* contact-form */

    .contact-form {

        /* form-row */

        .form-row {

            /* otp-zone */

            &.otp-zone {

                .wrapper {

                    .otp-input {
                        width: 35px;
                        height: 45px;
                        font-size: 32px;
                    }

                }

            }

        }

    }

}

/* SMART PHONE LANDSCAPE PART */
@media screen and (max-width:479px) {}

/* SMART PHONE LANDSCAPE PART */
@media (max-width:812px) and (orientation:landscape) {}

/* ========================== */
/* END OF RESPONSIVE CSS */
/* ========================== */