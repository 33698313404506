/* ========================== */
/* START OF LOGIN PAGE CSS */
/* ========================== */

/* wraper-login-main */

.wraper-login-main{

    /* login-main-overlay */
    
    > .login-main-overlay{
        top:0;
        right:0;
        bottom:0;
        width:50%;
        background-color:#2fabe1;
    }

    > .container{
    }

    /* login-main */

    .login-main{
        min-height:100vh;

        /* login-main-form */

        .login-main-form{
            position:relative;
            width:570px;
            flex:0 0 auto;
            padding:35px 70px 35px 90px;
            background-color:#fff;

            /* login-main-form-holder */

            > .login-main-form-holder{
                /*max-width:390px;*/
                max-height:calc( 100vh - 70px );
                /*margin-right:-20px;
                margin-left:0;*/
                padding-right:20px;
                /*overflow:hidden;*/
                overflow-x:hidden;

                &:hover{
                    /*padding-right:14px;
                    overflow:initial;
                    overflow-x:hidden;*/
                }
                
            }

            /* login-main-form-curve */

            .login-main-form-curve{
                top:0;
                left:100%;
                bottom:0;
                width:168px;

                img{
                    /*width:100%;*/
                    height:100%;
                }

            }

            /* login-main-form-heading */

            .login-main-form-heading{
                margin-bottom:25px;

                > *:last-child{
                    margin-bottom:0 !important;
                }

                .brand-logo{
                    margin-bottom:25px;

                    img{
                        margin-bottom:0;
                    }

                }

                .title{
                    font-size:24px;
                }

            }

            /* login-main-form-section */

            .login-main-form-section{
                /*margin-bottom:25px;*/
            }

            /* login-main-form-content */

            .login-main-form-content{
                max-width:360px;
                margin-bottom:50px;

                > *:last-child{
                    margin-bottom:0 !important;
                }

                p{
                    margin-bottom:15px;
                }

            }

            /* login-main-form-footer */

            .login-main-form-footer{

                ul.menu{
                    margin-bottom:10px;
                    
                    > li{
                        position:relative;
                        display:inline-block;
                        vertical-align:top;
                        margin-right:18px;
                        font-weight:400;
                        font-size:13px;
                        color:#414141;

                        &:before{
                            position:absolute;
                            top:4px;
                            right:-9px;
                            z-index:0;
                            content:" ";
                            width:1px;
                            height:8px;
                            background-color:#414141;
                        }

                        &:last-child,
                        &:only-child{
                            margin-right:0;

                            &:before{
                                display:none;
                            }

                        }
                        
                        > a{
                            color:inherit;
                            -webkit-transition:all 0.3s ease-in-out;
                            transition:all 0.3s ease-in-out;
                        }
                        
                        > a:hover,
                        &.current-menu-item > a{
                            color: $PrimaryThemeColor ;
                        }

                    }

                }

                .formfooter-note{
                    /*margin-bottom:15px;*/
                    font-size:11px;
                    color:#777;
                }

                .copyright-note{
                    margin-top:13px;
                    /*margin-bottom:15px;*/
                    font-weight:700;
                    font-size:12px;
                    line-height:1.2;
                }

            }

        }

        /* login-main-poster */

        .login-main-poster{
            /*max-height:100vh;*/
            padding:0 50px 0 100px;
            background:rgb(127,230,255);
            /*background:radial-gradient(circle, rgba(127,230,255,1) 0%, rgba(47,171,225,1) 68%, rgba(47,171,225,1) 100%);*/
            background:radial-gradient(circle, #7FE6FF 0%, #2FABE1 100%);

            /* login-main-poster-heading */

            .login-main-poster-heading{

                > *:last-child{
                    margin-bottom:0 !important;
                }

                .poster-image{
                    max-width:800px;
                    margin-bottom:10px;

                    img{
                        max-height:55vh;
                        margin-bottom:0;
                    }

                }

                .title{
                    max-width:560px;
                    margin-bottom:15px;
                    font-size:30px;
                    line-height:1.2;
                    color:#fff;
                }

            }

            /* login-main-poster-footer */

            .login-main-poster-footer{
                bottom:20px;

                .copyright-note{
                    /*margin-bottom:15px;*/
                    font-weight:700;
                    font-size:12px;
                    line-height:1.2;
                    color:#fff;
                }

            }

        }

    }

}

/* ========================== */
/* END OF LOGIN PAGE CSS */
/* ========================== */

/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */

/* EXTRA LARGE DESKTOP PART */
@media screen and (min-width:1920px){
}

/* EXTRA LARGE DESKTOP PART */
@media screen and (max-width:1919px){
}

/* LARGE DESKTOP PART */
@media screen and (max-width:1400px){

    /* ========================== */
    /* START OF LOGIN PAGE CSS */
    /* ========================== */

    /* wraper-login-main */

    .wraper-login-main{

        /* login-main */

        .login-main{

            /* login-main-form */

            .login-main-form{
                width:510px;
                padding:35px 40px 35px 60px;
            }

            /* login-main-poster */

            .login-main-poster{

                /* login-main-poster-heading */

                .login-main-poster-heading{

                    .title{
                        font-size:25px;
                    }

                }

            }

        }
        
    }

    /* ========================== */
    /* END OF LOGIN PAGE CSS */
    /* ========================== */

}

/* DESKTOP PART */
@media screen and (max-width:1199px){
}

/* TABLET LANDSCAPE PART */
@media screen and (max-width:991px){

    /* ========================== */
    /* START OF LOGIN PAGE CSS */
    /* ========================== */

    /* wraper-login-main */

    .wraper-login-main{

        /* login-main */

        .login-main{
            /*min-height:initial;*/

            /* login-main-form */

            .login-main-form{
                width:100%;
                padding:35px 20px 35px 20px;

                /* login-main-form-holder */

                > .login-main-form-holder{
                    max-width:500px;
                    max-height:initial;
                    padding-right:0;
                }

            }

        }
        
    }

    /* ========================== */
    /* END OF LOGIN PAGE CSS */
    /* ========================== */

}

/* TABLET PROTRAIT PART */
@media screen and (max-width:767px){
}

/* HIGH DEFINITION PHONE PART */
@media screen and (max-width:575px){
}

/* SMART PHONE LANDSCAPE PART */
@media screen and (max-width:479px){
}

/* SMART PHONE LANDSCAPE PART */
@media (max-width:812px) and (orientation:landscape){
}

/* ========================== */
/* END OF RESPONSIVE CSS */
/* ========================== */
