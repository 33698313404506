/* ========================== */
/* START OF MODAL CSS */
/* ========================== */

/* login-getcode-modal */

.login-getcode-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:20px;
		background:rgb(47,171,225);
		background:linear-gradient(90deg, rgba(47,171,225,1) 0%, rgba(127,230,255,1) 100%);
		border-radius:0;
	}

	/* login-getcode-modal-holder */

	.login-getcode-modal-holder{

		/* close-btn */
		
		> .close-btn{
			top:10px;
			right:10px;
			font-size:21px;
			color:#666;
			line-height:1; 
			cursor:pointer;
		}

		/* mat-dialog-content */

		.mat-dialog-content{
			margin:0 auto;
			padding:0;
			max-height:85vh;
		}

		/* login-getcode-modal-main */

		.login-getcode-modal-main{
			padding:20px 20px 25px 20px;
			background-color:#fff;
			box-shadow:0 7px 40px rgba(47,171,225,0.12);
			border-radius:5px;
			overflow-x:hidden;
		}

		/* login-getcode-modal-heading */

		.login-getcode-modal-heading{
			margin-bottom:40px;

			> *:last-child{
				margin-bottom:0 !important;
			}

			.title{
				margin-bottom:7px;
				font-size:24px;
			}

		}

		/* login-getcode-modal-verify */

		.login-getcode-modal-verify{
			max-width:500px;
			margin:0;
			padding:0;

			/* verify-item */

			.verify-item{
				margin-bottom:20px;
				padding:30px 20px 30px 30px;
				background-color:#fff;
				border:1px solid #d8e0e4;
				border-radius:5px;
				cursor:pointer;

				&:last-child,
				&:only-child{
					margin-bottom:0;
				}

				.verify-item-icon{
					width:60px;
					min-width:60px;

					img{
						max-height:60px;
					}

				}

				.verify-item-data{
					
					> *:last-child{
						margin-bottom:0 !important;
					}
		
					.title{
						margin-bottom:13px;
						font-size:16px;
						color:#54565b;
					}

					.subtitle{
						margin-bottom:10px;
						font-size:16px;
						color:#8b8d91;
					}

				}
				
			}

		}

		/* login-getcode-modal-otp */

		.login-getcode-modal-otp{
			max-width:580px;

			/* form-row */

			.form-row{
				margin-bottom:25px;
		
				/* mat-button */

				.mat-flat-button.mat-primary,
				.mat-raised-button.mat-primary,
				.mat-fab.mat-primary,
				.mat-mini-fab.mat-primary{
					min-width:220px;
				}

			}

		}

		/* login-getcode-modal-upload */

		.login-getcode-modal-upload{
			max-width:640px;
			margin-bottom:50px;

			/* ngx-dropzone */

			ngx-dropzone{
				height:auto;
				min-height:50vh;
				/*max-height:300px;*/
				border:4px dashed #dfdfdf;
				font-size:14px;
				color:#414244;

				/* ngx-dropzone-label */

				ngx-dropzone-label{
					margin:0 auto;
					padding:30px 20px 30px 20px;

					/* notice-icon */

					.notice-icon{
						margin-bottom:25px;

						i{
							font-size:95px;
							color: $PrimaryThemeColor ;
							line-height:1;
						}

					}

					/* notice-data */

					.notice-data{
						max-width:340px;
						
						> *:last-child{
							margin-bottom:0 !important;
						}

						p{
							margin-bottom:12px;

							strong{
								color:#000;
							}

						}

					}

				}

			}
			
		}

		/* login-getcode-modal-actionarea */

		.login-getcode-modal-actionarea{

			button{
				min-width:175px;
				margin-right:5px;
				margin-bottom:10px;
				margin-left:5px;

				&[disabled]{
					background-color:transparent;
					color: $PrimaryThemeColor ;
					opacity:0.5;
				}

			}

		}

		/* login-getcode-modal-createteam */

		.login-getcode-modal-createteam{
			max-width:860px;
			max-height:initial;

			.row{
				margin-right:-15px;
				margin-left:-15px;

				> [class*=col-]{
					padding-right:15px;
					padding-left:15px;
				}

			}

		}

	}

}

/* toc-modal */

.toc-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:20px;
		background:rgb(47,171,225);
		background:linear-gradient(90deg, rgba(47,171,225,1) 0%, rgba(127,230,255,1) 100%);
		border-radius:0;
	}

}

/* profile-menu-modal */

.profile-menu-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:0;
		background:transparent;
		border-radius:0;
	}

}

/* delete-word-backdrop */

.delete-word-backdrop{
	-webkit-backdrop-filter:blur(2px);
	backdrop-filter:blur(2px);
	background-color:rgba(16,24,32,0.75);
}

/* delete-word-modal */

.delete-word-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:0 !important;
		background:transparent;
		border-radius:0;
	}

	/* delete-word-modal-holder */

	.delete-word-modal-holder{
		padding:32px 35px 20px 35px;
		background-color:#fff;
		box-shadow:0 5px 20px rgba(0,0,0,0.05);
		border-radius:5px;
		overflow-x:hidden;

		/* delete-word-modal-heading */

		.delete-word-modal-heading{
			margin-bottom:28px;

			> *:last-child{
				margin-bottom:0 !important;
			}

			.title{
				margin-bottom:12px;
				font-size:18px;
				color:#000;
			}

			.subtitle{
				color:#101820;
			}

		}

		/* delete-word-modal-action */

		.delete-word-modal-action{
			
			.mat-button{
				font-family: $Montserrat ;
				font-weight:600;
				font-size:13px;

				&.mat-primary{
					color: $PrimaryThemeColor ;
				}

			}

		}

	}

}

/* add-teammate-modal */

.add-teammate-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:0;
		background:transparent;
		border-radius:0;
	}

	/* add-teammate-modal-holder */

	.add-teammate-modal-holder{
		padding:55px 35px 30px 35px;
		background-color:#fff;
		box-shadow:0 5px 20px rgba(0,0,0,0.05);
		border-radius:5px;
		overflow-x:hidden;

		/* close-btn */
			
		> .close-btn{
			top:13px;
			right:13px;
			font-size:23px;
			color:#666;
			line-height:1; 
			cursor:pointer;
		}

		/* add-teammate-modal-heading */

		.add-teammate-modal-heading{
			margin-bottom:40px;

			> *:last-child{
				margin-bottom:0 !important;
			}

			.title{
				font-size:20px;
				color:#000;
			}

		}

		/* add-teammate-modal-selecttype */

		.add-teammate-modal-selecttype{

			/* add-teammate-modal-selecttype-item */

			.add-teammate-modal-selecttype-item{
				margin-bottom:25px;
				padding-right:20px;
				padding-left:20px;
				border-right:1px solid rgba(0,0,0,0.15);
				cursor:pointer;

				&:last-child,
				&:only-child{
					border-right:none;
				}

				> .holder{

					> .icon{
						margin-bottom:40px;

						img{
							max-height:100px;
						}

					}

					> .data{

						> *:last-child{
							margin-bottom:0 !important;
						}
			
						.title{
							font-family: $Montserrat ;
							font-weight:700;
							font-size:16px;
							color:#54565b;
						}

					}

				}

			}

		}

		/* add-teammate-modal-form */

		.add-teammate-modal-form{
			max-height:initial;
		}

	}

}

/* payment-success-modal */

.payment-success-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:0;
		background:transparent;
		border-radius:0;
	}

	/* payment-success-modal-holder */

	.payment-success-modal-holder{
		padding:50px 35px 50px 35px;
		background-color:#fff;
		box-shadow:0 5px 20px rgba(0,0,0,0.05);
		border-radius:5px;
		overflow-x:hidden;

		/* payment-success-modal-heading */

		.payment-success-modal-heading{
			margin-bottom:35px;

			> *:last-child{
				margin-bottom:0 !important;
			}

			.title{
				margin-bottom:12px;
				font-size:18px;
			}

			.subtitle{
				font-size:16px;
			}

		}

		/* payment-success-modal-content */

		.payment-success-modal-content{
			margin:0 0 40px 0;
			padding:0;

			dl{

				> *:last-child{
					margin-bottom:0 !important;
				}

				dt{
					margin-bottom:7px;
					font-family: $Montserrat ;
					font-weight:700;
					color: $SectionTitleColor ;
				}

				dd{
					margin-bottom:20px;
					color:#222528;
				}

			}

		}

		/* payment-success-modal-actionarea */

		.payment-success-modal-actionarea{
		}

	}

}

/* preference-settings-modal */

.preference-settings-modal,
.hot-key-modal,
.export-file-modal,
.share-file-modal,
.manage-access-modal,
.add-new-label-modal,
.file-select-modal,
.file-preview-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:0;
		background:transparent;
		border-radius:0;
	}

}

/* view-template-modal */

.view-template-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:20px;
		background:transparent;
		border-radius:0;
	}

}

/* template-specs-modal */

.template-specs-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:20px;
		background:rgb(47,171,225);
		background:linear-gradient(90deg, rgba(47,171,225,1) 0%, rgba(127,230,255,1) 100%);
		border-radius:0;
	}

}

/* login-helper-modal */

.login-helper-modal{

	/* mat-dialog-container */

	.mat-dialog-container{
		padding:0;
		background-color:#101820;
		border-radius:5px;
		box-shadow:0 6px 20px rgba(0,0,0,0.13);
	}

}

/* ========================== */
/* END OF MODAL CSS */
/* ========================== */

/* ========================== */
/* START OF RESPONSIVE CSS */
/* ========================== */

/* EXTRA LARGE DESKTOP PART */
@media screen and (min-width:1920px){
}

/* EXTRA LARGE DESKTOP PART */
@media screen and (max-width:1919px){
}

/* LARGE DESKTOP PART */
@media screen and (max-width:1400px){
}

/* DESKTOP PART */
@media screen and (max-width:1199px){
}

/* TABLET LANDSCAPE PART */
@media screen and (max-width:991px){
}

/* TABLET PROTRAIT PART */
@media screen and (max-width:767px){
}

/* HIGH DEFINITION PHONE PART */
@media screen and (max-width:575px){

	/* ========================== */
	/* START OF MODAL CSS */
	/* ========================== */

	/* login-getcode-modal */

	.login-getcode-modal{

		/* login-getcode-modal-holder */

		.login-getcode-modal-holder{

			/* login-getcode-modal-heading */

			.login-getcode-modal-heading{
				margin-bottom:25px;
			}

			/* login-getcode-modal-verify */

			.login-getcode-modal-verify{

				/* verify-item */

				.verify-item{
					padding:20px 20px 20px 20px;

					.verify-item-icon{
						width:40px;
						min-width:40px;

						img{
							max-height:40px;
						}

					}

				}

			}

		}

	}

	/* profile-menu-modal */

	.profile-menu-modal{
		width:260px !important;

		/* profile-menu-modal-holder */

		.profile-menu-modal-holder{
			padding:20px;

			/* profile-menu-modal-heading */

			.profile-menu-modal-heading{
				margin-bottom:20px;
				padding-bottom:20px;

				.icon{

					img{
						width:42px;
						height:42px;
					}
					
				}

				.data{

					.title{
						font-size:14px;
					}
					
				}

			}

			/* profile-menu-modal-list */

			.profile-menu-modal-list{
				
				ul{

					> li{
						margin-bottom:8px;

						> a{
							font-size:12px;
						}

					}

				}

			}

		}

	}

	/* delete-word-modal */

    .delete-word-modal{

        /* delete-word-modal-holder */

        .delete-word-modal-holder{
            padding:28px 25px 30px 25px;
        }

    }

	/* ========================== */
	/* END OF MODAL CSS */
	/* ========================== */

}

/* SMART PHONE LANDSCAPE PART */
@media screen and (max-width:479px){
}

/* SMART PHONE LANDSCAPE PART */
@media (max-width:812px) and (orientation:landscape){
}

/* ========================== */
/* END OF RESPONSIVE CSS */
/* ========================== */
